/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { IContentModel } from 'app/interfaces/content-model.interface';
import { IModelTranslation } from 'app/interfaces/model-translation.interface';
import { Language } from 'app/enums/language.enum';
import { Recipe } from 'app/models/recipe.model';
import { Collection } from 'app/models/collection.model';
import { Tip } from 'app/models/tip.model';
import { ModelTypePrefix } from 'app/enums/model-type-prefix.enum';
import { CountryProfile } from 'app/models/country-profile.model';

export function getContentLabel(content: IContentModel, language?: Language): string {
	if (!content) {
		return '-';
	}

	if (content instanceof CountryProfile) {
		return `${content.name} - ${content.email}`;
	}

	let contentTitle: string = content.defaultTranslation ? content.defaultTranslation.title : 'n/a';

	if (language) {
		let contentTranslations: Array<IModelTranslation>;

		if (content.modelTypePrefix === ModelTypePrefix.RECIPE) {
			contentTranslations = (content as Recipe).recipeTranslations;
		}
		if (content.modelTypePrefix === ModelTypePrefix.TIP) {
			contentTranslations = (content as Tip).tipTranslations;
		}
		if (content.modelTypePrefix === ModelTypePrefix.COLLECTION) {
			contentTranslations = (content as Collection).collectionTranslations;
		}

		const contentTranslation: IModelTranslation = contentTranslations.find(
			(translation: IModelTranslation) => translation.language === language,
		);

		if (contentTranslation) {
			contentTitle = contentTitle || contentTranslation.title;
		}
	}

	return `${content.shortId} - ${contentTitle}`;
}
