/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HalModel, HasMany, HasOne, ModelConfig } from 'ngx-hal';
import { ElectricSystem } from '../enums/electric-system.enum';
import { PowerUnit } from '../enums/power-unit.enum';
import { PressureUnit } from '../enums/pressure-unit.enum';
import { TemperatureUnit } from '../enums/temperature-unit.enum';
import { IDeviceOptionLimits } from '../interfaces/device-config.interface';

@ModelConfig({
	type: 'CookingMethod',
	endpoint: 'CookingMethod',
})
export class CookingMethod extends HalModel {
	@Attribute()
	public name: string;

	@Attribute()
	public category: string;

	@Attribute()
	public temperature: IDeviceOptionLimits<TemperatureUnit>;

	@Attribute()
	public time: Omit<IDeviceOptionLimits, 'unit'>;

	@Attribute()
	public humidity: Pick<IDeviceOptionLimits, 'adjustable' | 'default' | 'suggested'>;

	@Attribute()
	public pressure: IDeviceOptionLimits<PressureUnit>;

	@Attribute()
	public power: IDeviceOptionLimits<PowerUnit>;

	@Attribute()
	public electricSystem: ElectricSystem;

	@HasOne({ propertyClass: CookingMethod })
	public parent: CookingMethod;

	@HasMany({
		itemsType: CookingMethod,
	})
	public children: Array<CookingMethod>;
}
