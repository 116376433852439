/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, BelongsTo, Attribute } from 'angular2-jsonapi';
import { Attribute as FormAttribute, BelongsTo as FormBelongsTo } from 'ngx-form-object';

import { Domain } from '../models/domain.model';
import { User } from '../models/user.model';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { UserRole } from '../enums/user-role.enum';

@JsonApiModelConfig({
	type: 'userDomains',
	meta: ModelMetadata,
})
export class UserDomain extends JsonApiModel {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public role: UserRole;

	@BelongsTo()
	@FormBelongsTo()
	public domain: Domain;

	@BelongsTo()
	public user: User;
}
