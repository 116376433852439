/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from './hal-datastore-model.model';

export interface IFoodNutrition {
	nutrient: string;
	unit: string;
	value: number;
}

@ModelConfig({
	type: 'FoodComposition',
})
export class FoodComposition extends HalDatastoreModel {
	@Attribute()
	public source: string;

	@Attribute()
	public internationalFoodCode: string;

	@Attribute()
	public provider: string;

	@Attribute()
	public providerId: string;

	@Attribute()
	public providerText: string;

	@Attribute()
	public referenceQuantity: number;

	@Attribute()
	public referenceUnit: string;

	@Attribute()
	public lastRefreshed: string;

	@Attribute()
	public category: string;

	@Attribute()
	public subcategory: string;

	@Attribute()
	public nutrition: Array<IFoodNutrition>;
}
