/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HalModel, ModelConfig, Attribute } from 'ngx-hal';

import { NutrientUnit } from '../enums/nutrient-unit.enum';
import { NutrientCategory } from '../custom-types/nutrient-category.type';
import { NutrientCode } from '../enums/nutrient-code.enum';

@ModelConfig({
	type: 'Nutrient',
	endpoint: 'Nutrient',
})
export class Nutrient extends HalModel {
	@Attribute()
	public category: NutrientCategory;

	@Attribute()
	public code: NutrientCode;

	@Attribute()
	public name: string;

	@Attribute()
	public unit: NutrientUnit;
}
