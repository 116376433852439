/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModel } from 'angular2-jsonapi';

import { FormObject, FormObjectOptions, FormStore } from 'ngx-form-object';
import { HalModel } from 'ngx-hal';
import { Observable } from 'rxjs';
import { JSON_API_MODEL_CONFIG } from '../../../assets/vendors/angular2-jsonapi/constants/json-api.constants';
import { getObjProperty } from '../../../assets/vendors/angular2-jsonapi/helpers/class-metadata.helper';
import { IRelationshipChanges } from '../../interfaces/relationship-changes.interface';
import { contains } from '../../utils/helpers/helpers';

const modelOptions: FormObjectOptions = {};

export class BaseFormObject<T extends HalModel | JsonApiModel> extends FormObject {
	constructor(
		public model: T,
		protected options: FormObjectOptions,
	) {
		super(model, modelOptions);
	}

	public getModelType(model: T): string {
		if (model instanceof HalModel) {
			return model.type;
		}

		const modelConfigMetadata = getObjProperty(model.constructor, JSON_API_MODEL_CONFIG);
		return modelConfigMetadata.type;
	}

	protected save(model: T): Observable<T> {
		return model.save() as Observable<T>;
	}

	protected getModelChanges<Model extends HalModel, Form extends FormStore>(
		modelForms: Array<Form>,
		savedModels: Array<Model>,
	): IRelationshipChanges<Model> {
		const saveChanges: IRelationshipChanges<Model> = this.saveOrCreateModels(modelForms);

		const deleteChanges: IRelationshipChanges<Model> = this.deleteModels(modelForms, savedModels);

		const changes: IRelationshipChanges<Model> = {
			adds: saveChanges.adds.concat(deleteChanges.adds),
			edits: saveChanges.edits.concat(deleteChanges.edits),
			deletes: saveChanges.deletes.concat(deleteChanges.deletes),
			all: saveChanges.all.concat(deleteChanges.all),
		};

		return changes;
	}

	private saveOrCreateModels<Form extends FormStore, Model extends HalModel>(
		forms: Array<Form>,
	): IRelationshipChanges<Model> {
		const changes: IRelationshipChanges<Model> = {
			adds: [],
			edits: [],
			deletes: [],
			all: [],
		};

		forms.forEach((form: Form) => {
			const isNewModel = !form.model.isSaved;

			if (isNewModel || form.isChanged) {
				const modelSave$: Observable<Model> = form.save();
				changes.all.push(modelSave$);

				if (isNewModel) {
					changes.adds.push(modelSave$);
				} else {
					changes.edits.push(modelSave$);
				}
			}
		});

		return changes;
	}

	private deleteModels<Form extends FormStore, Model extends HalModel>(
		forms: Array<Form>,
		savedModels: Array<Model> = [],
	): IRelationshipChanges<Model> {
		const changes: IRelationshipChanges<Model> = {
			adds: [],
			edits: [],
			deletes: [],
			all: [],
		};

		const currentFormModels: Array<Model> = forms.map((form) => form.model);

		savedModels.forEach((model: Model) => {
			if (!contains(currentFormModels, model)) {
				const modelDelete$ = model.delete();
				changes.deletes.push(modelDelete$);
				changes.all.push(modelDelete$);
			}
		});

		return changes;
	}
}
