/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HalModel, HasMany, HasOne, ModelConfig } from 'ngx-hal';
import { ReportReason } from '../enums/report-reason.enum';
import { ReportedContentStatus } from '../enums/reported-content-status.enum';
import { Author } from './author.model';
import { Country } from './country.hal.model';
import { Device } from './device.hal.model';

@ModelConfig({
	type: 'Flag',
})
export class Flag extends HalModel {
	@Attribute()
	public reason: ReportReason;

	@Attribute()
	public text: string;

	@Attribute()
	public status: ReportedContentStatus;

	@HasOne({ propertyClass: 'Author' })
	public author: Author;

	@Attribute()
	public reportedAt: Date;

	@Attribute()
	public reviewedAt: Date;

	@HasMany({ itemsType: 'Device' })
	public devices: Array<Device>;

	@HasOne({ propertyClass: Country })
	public country: Country;
}
