/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { Country, CountryCode, CountryData } from 'app/custom-types/country/country.type';
import { DomainId } from 'app/enums/domain-id.enum';
import { Language } from 'app/enums/language.enum';
import { CountryProfile } from 'app/models/country-profile.model';
import { Domain } from 'app/models/domain.model';
import { Datastore } from 'app/services/datastore/datastore.service';
import { LanguageService } from 'app/services/language/language.service';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { countriesData } from '../../enums/countries-data.enum';
import { ISelectInterface2 } from '../../interfaces/select.interface';

@Injectable()
export class DomainService {
	private cachedDomains: Array<Domain>;

	public constructor(
		private readonly datastore: Datastore,
		private readonly languageService: LanguageService,
	) {}

	public initDomains(): Observable<Array<Domain>> {
		const includes = ['countryProfiles', 'countryProfiles.countryInfo'];

		return this.fetchDomains(includes).pipe(
			tap((domains: Array<Domain>) => {
				this.domains = domains;
			}),
		);
	}

	get domains(): Array<Domain> {
		return this.cachedDomains;
	}

	set domains(domains: Array<Domain>) {
		this.cachedDomains = domains;
	}

	get countries(): Array<CountryCode> {
		return this.domains
			.map((domain: Domain) => domain.responsibleFor)
			.reduce((result: Array<CountryCode>, countries: Array<CountryCode>) => {
				result.push(...countries);
				return result;
			}, []);
	}

	public getCountriesSelect(): Array<ISelectInterface2<{ id: string }>> {
		return this.countries.map((countryCode: CountryCode) => {
			const countryData = countriesData[countryCode];
			const country = new CountryData(countryCode, countryData);

			return {
				value: { id: countryCode },
				label: country.toString(),
			};
		});
	}

	public getDomainsForSelect(): Array<ISelectInterface2<{ id: string }>> {
		return this.domains.map((domain: Domain) => ({
			value: { id: domain.id },
			label: domain.name,
		}));
	}

	public getDomainSupportedLanguages(domain: Domain): Array<Language> {
		if (this.globalDomain && domain.id === this.globalDomain.id) {
			return Object.keys(this.languageService.selectableLanguages).map(
				(languageId: string | Language) =>
					this.languageService.getLanguageData(languageId as Language).id,
			);
		}

		if (!domain.countryProfiles) {
			console.warn('Domain does not have country profiles fetched.');
			return [];
		}

		return domain.countryProfiles.reduce(
			(supportedLanguages: Array<Language>, countryProfile: CountryProfile) => {
				if (countryProfile) {
					return supportedLanguages.concat(countryProfile.countryInfo.supportedLanguages);
				}

				return supportedLanguages;
			},
			[],
		);
	}

	public getDomainForCountry(country: Country): Domain {
		return this.domains.find((domain: Domain) => {
			if (!domain.countryProfiles) {
				return false;
			}

			return domain.countryProfiles.some(
				(countryProfile: CountryProfile) =>
					countryProfile.countryInfo.country['countryCode'] === country['countryCode'],
			);
		});
	}

	public get globalDomain(): Domain {
		return this.domains.find((domain: Domain) => domain.name === DomainId.GLOBAL);
	}

	public getLanguagesForCountry(forCountry: Country): Array<Language> {
		const countryDomain: Domain = this.domains.find((domain: Domain) => {
			if (!domain.countryProfiles) {
				return false;
			}

			return domain.countryProfiles.some(
				(cp: CountryProfile) => cp.countryInfo.country['countryCode'] === forCountry,
			);
		});

		if (!countryDomain) {
			return null;
		}

		const countryProfile: CountryProfile = countryDomain.countryProfiles.find(
			(cp: CountryProfile) => cp.countryInfo.country['countryCode'] === forCountry,
		);

		if (
			!countryProfile ||
			!countryProfile.countryInfo ||
			!countryProfile.countryInfo.supportedLanguages ||
			(countryProfile.countryInfo.supportedLanguages &&
				!countryProfile.countryInfo.supportedLanguages.length)
		) {
			return null;
		}

		return countryProfile.countryInfo.supportedLanguages;
	}

	// Domains are something that is not changed often, therefore, it's
	// enough to fetch them only once
	public fetchDomains(includes: Array<string> = []): Observable<Array<Domain>> {
		const domains$: Subject<Array<Domain>> = new Subject();

		this.datastore
			.findAll(Domain, {
				include: includes.join(','),
			})
			.subscribe((domainsData: JsonApiQueryData<Domain>) => {
				domains$.next(domainsData.getModels());
			});

		return domains$;
	}
}
