/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HasMany as FormHasMany } from 'ngx-form-object';
import { HasMany, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { Device } from './device.hal.model';
import { Product } from './product.model';

@ModelConfig({
	type: 'Accessory',
})
export class Accessory extends Product {
	public static modelType = 'Accessory';
	public displayNameTranslationKey = 'Accessory';
	public readonly isAccessory = true;
	public readonly isDevice = false;
	public readonly isConnectable = false;

	@HasMany({
		itemsType: 'Device',
	})
	@FormHasMany({
		isChanged: (initial: Array<Device>, current: Array<Device>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Device) => item.id,
			}),
	})
	public devices: Array<Device>;

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.accessoryCollectionEndpoint,
			singleResourceEndpoint: this.datastore.rootApi.singleAccessoryEndpoint,
		};
	}

	public get displayName(): string {
		return this.name;
	}
}
