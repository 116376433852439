/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from './hal-datastore-model.model';

@ModelConfig({
	type: 'User',
})
export class User extends HalDatastoreModel {
	@Attribute()
	public email: string;

	@Attribute()
	public phoneNumber: string;

	@Attribute()
	public additionalData: Record<string, string>;
}
