/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HalModel, Attribute, ModelConfig } from 'ngx-hal';
import { Attribute as FormAttribute } from 'ngx-form-object';

import { Language } from '../enums/language.enum';

@ModelConfig({
	type: 'IngredientTranslation',
	endpoint: 'Translation',
})
export class IngredientTranslation extends HalModel {
	@Attribute()
	@FormAttribute()
	public nameSingular: string;

	@Attribute()
	@FormAttribute()
	public namePlural: string;

	@Attribute({
		transformResponseValue: (rawValue: Language) => rawValue && rawValue.replace('-', '_'),
	})
	@FormAttribute()
	public language: Language;
}
