/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HalModel, HasMany, ModelConfig } from 'ngx-hal';
import { Category } from './category.model';

@ModelConfig({
	type: 'RootApi',
})
export class RootApi extends HalModel {
	@HasMany({ itemsType: Category })
	public countryProfileTypeCategories: Array<Category>;

	public get pmsRootApiEndpoint(): string {
		return this.getResourceLink('pmsBaseUrl');
	}

	public get singleRecipeEndpoint(): string {
		return this.getResourceLink('recipe');
	}

	public get recipeCollectionEndpoint(): string {
		return this.getResourceLink('recipes');
	}

	public get recipeSearchEndpoint(): string {
		return this.getResourceLink('recipeSearch');
	}

	public get singleAnnouncementEndpoint(): string {
		return this.getResourceLink('announcement');
	}

	public get announcementCollectionEndpoint(): string {
		return this.getResourceLink('announcements');
	}

	public get singleContentContainerEndpoint(): string {
		return this.getResourceLink('contentContainer');
	}

	public get contentContainerCollectionEndpoint(): string {
		return this.getResourceLink('contentContainers');
	}

	public get publicationCollectionEndpoint(): string {
		return this.getResourceLink('publication');
	}

	public get singleMediaEndpoint(): string {
		return this.getResourceLink('mediaById');
	}

	public get mediaCollectionEndpoint(): string {
		return this.getResourceLink('media');
	}

	public get singlePushMessageEndpoint(): string {
		return this.getResourceLink('pushMessage');
	}

	public get collectionPushMessageEndpoint(): string {
		return this.getResourceLink('pushMessages');
	}

	public get singleArticleEndpoint(): string {
		return this.getResourceLink('article');
	}

	public get collectionArticlesEndpoint(): string {
		return this.getResourceLink('articles');
	}

	public get singlePublicationEndpoint(): string {
		return this.getResourceLink('publicationById');
	}

	public get categoryCollectionEndpoint(): string {
		return this.getResourceLink('categories');
	}

	public get collectionScheduledEventEndpoint(): string {
		return this.getResourceLink('scheduledEvents');
	}

	public get singleScheduledEventEndpoint(): string {
		return this.getResourceLink('scheduledEvent');
	}

	public get collectionsEndpoint(): string {
		return this.getResourceLink('collections');
	}

	public get singleCollectionEndpoint(): string {
		return this.getResourceLink('collection');
	}

	public get countriesEndpoint(): string {
		return this.getResourceLink('countries');
	}

	public get singleCountryEndpoint(): string {
		return this.getResourceLink('country');
	}

	public get commentsCollectionEndpoint(): string {
		return this.getResourceLink('comments');
	}

	public get singleCommentEndpoint(): string {
		return this.getResourceLink('comment');
	}

	public get articleCommentsCollectionEndpoint(): string {
		return this.getResourceLink('articleComments');
	}

	public get recipeCommentsCollectionEndpoint(): string {
		return this.getResourceLink('recipeComments');
	}

	public get collectionRecipesCollectionEndpoint(): string {
		return this.getResourceLink('collectionRecipes');
	}

	public get contentTranslationCollectionEndpoint(): string {
		return this.getResourceLink('contentTranslationImport');
	}

	public get contentTranslationImportEndpoint(): string {
		return this.getResourceLink('contentTranslationImport');
	}

	public get contentTranslationExportEndpoint(): string {
		return this.getResourceLink('contentTranslationImport');
	}

	public get administratorSelfEndpoint(): string {
		return this.getResourceLink('administratorSelf');
	}

	public get singleAdministratorEndpoint(): string {
		return this.getResourceLink('administrator');
	}

	public get administratorCollectionEndpoint(): string {
		return this.getResourceLink('administrators');
	}

	public get organizationCollectionEndpoint(): string {
		return this.getResourceLink('organizations');
	}

	public get singleFoodEndpoint(): string {
		return this.getResourceLink('foodById');
	}

	public get foodCollectionEndpoint(): string {
		return this.getResourceLink('food');
	}

	public get foodDatabaseCollectionEndpoint(): string {
		return this.getResourceLink('foodDatabases');
	}

	public get profileCollectionEndpoint(): string {
		return this.getResourceLink('profiles');
	}

	public get singleProfileEndpoint(): string {
		return this.getResourceLink('profile');
	}

	public get productCollectionEndpoint(): string {
		return this.getResourceLink('products');
	}

	public get singleDeviceEndpoint(): string {
		return this.getResourceLink('device');
	}

	public get deviceCollectionEndpoint(): string {
		return this.getResourceLink('devices');
	}

	public get singleAccessoryEndpoint(): string {
		return this.getResourceLink('accessory');
	}

	public get accessoryCollectionEndpoint(): string {
		return this.getResourceLink('accessories');
	}

	public get singleToolEndpoint(): string {
		return (
			this.getResourceLink('tool') ||
			'https://dev.backend.vbs.versuni.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Tool/{id}'
		);
	}

	public get toolCollectionEndpoint(): string {
		return this.getResourceLink('tools');
	}

	public get singleMessageEndpoint(): string {
		return this.getResourceLink('message');
	}

	public get collectionMessageEndpoint(): string {
		return this.getResourceLink('messages');
	}

	public get nonPrefixedSelf(): string {
		// TODO NUT-44725 remove this getter
		const splittedSelf = this.getResourceLink('self').split('/');
		splittedSelf.pop();

		return splittedSelf.join('/');
	}

	public get collectionTranslationImportJobEndpoint(): string {
		return this.getResourceLink('translationImportJobs');
	}

	public get singleTranslationImportJobEndpoint(): string {
		return this.getResourceLink('translationImportJob');
	}

	public get prxLanguagesEndpoint(): string {
		return this.getResourceLink('prxLanguages');
	}

	public get prxLocalesEndpoint(): string {
		return this.getResourceLink('prxLocales');
	}

	public get customTableSingleResourceEndpoint(): string {
		return this.getResourceLink('customTableById');
	}

	public get customTableCollectionEndpoint(): string {
		return this.getResourceLink('customTables');
	}

	public get endpoint(): string {
		return '';
		// return TEMPORARY_SPACE_ID;
	}

	private getResourceLink(resourceName: string): string {
		return this.links[resourceName]?.href;
	}
}
