/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HalDocument, HalModel, RequestOptions } from 'ngx-hal';
import { Observable, of } from 'rxjs';
import { CustomHeader } from '../../enums/custom-header.enum';
import { CacheFirstStorage } from './cache-first.storage';

interface ICachedEndpoint {
	regex: RegExp;
	timeToCache: number;
}

const LONG_CACHING = 5 * 60 * 1000; // 5 minutes

export class CacheableStorage extends CacheFirstStorage {
	private readonly cacheableEndpoints: Array<ICachedEndpoint> = [
		{
			regex: /api\/.{36}\/Country\/.{36}$/g, // api/spaceId/Country/Id
			timeToCache: LONG_CACHING,
		},
		{
			regex: /api\/.{36}\/Organization(\?.*|$)/g, // api/spaceId/Organization
			timeToCache: LONG_CACHING,
		},
		{
			regex: /api\/.{36}\/Organization\/.{36}\/Country$/g, // api/spaceId/Organization/organizationId/Country
			timeToCache: LONG_CACHING,
		},
		{
			regex: /api\/.{36}\/Profile\/.{36}$/g, // api/spaceId/Profile/Id
			timeToCache: LONG_CACHING,
		},
	];

	public makeGetRequestWrapper<T extends HalModel>(
		urls: { originalUrl: string; cleanUrl: string; urlWithParams: string },
		cachedResource: T | HalDocument<T>,
		originalGetRequest$: Observable<T | HalDocument<T>>,
		requestOptions?: RequestOptions,
	): Observable<T | HalDocument<T>> {
		const cachedEndpoint: ICachedEndpoint = this.cacheableEndpoints.find(
			(cacheableEndpoint: ICachedEndpoint) => urls.urlWithParams.match(cacheableEndpoint.regex),
		);

		if (cachedEndpoint && !this.shouldSkipCache(requestOptions)) {
			const timestamp = this.getRawStorageModel(
				cachedResource?.uniqueModelIdentificator,
			)?.timestamp;
			if (cachedResource && timestamp && Date.now() - timestamp < cachedEndpoint.timeToCache) {
				return of(cachedResource);
			}

			return super.makeGetRequestWrapper(urls, cachedResource, originalGetRequest$);
		} else {
			return originalGetRequest$;
		}
	}

	private shouldSkipCache(requestOptions: RequestOptions): boolean {
		return Boolean(requestOptions?.headers[CustomHeader.CACHE_BUST]);
	}
}
