/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasMany, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { UserRole } from '../enums/user-role.enum';
import { UserStatus } from '../enums/user-status.enum';
import { UserDomain } from '../models/user-domain.model';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';

@JsonApiModelConfig({
	type: 'users',
	meta: ModelMetadata,
})
export class User extends JsonApiModel {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public username: string;

	@Attribute()
	@FormAttribute()
	public email: string;

	@Attribute()
	@FormAttribute({
		isChanged: (initial: Array<UserRole>, current: Array<UserRole>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
			}),
	})
	public roles: Array<UserRole>;

	@Attribute()
	@FormAttribute()
	public name: string;

	@Attribute()
	@FormAttribute()
	public createdAt: Date;

	@Attribute()
	@FormAttribute()
	public modifiedAt: Date;

	@Attribute()
	@FormAttribute()
	public preferredLanguage: string;

	@Attribute()
	@FormAttribute()
	public status: UserStatus;

	@Attribute()
	@FormAttribute()
	public company: string;

	@HasMany()
	@FormHasMany()
	public userDomains: Array<UserDomain> = [];

	@FormHasMany({
		isChanged: (initial: Array<UserDomain>, current: Array<UserDomain>) => {
			if (initial && current) {
				return !compareArrays(initial, current, {
					ignoreOrder: true,
					propertyFn: (item: UserDomain) => item?.domain.name || '',
				});
			}
			return false;
		},
	})
	public get contentManagerDomains(): Array<UserDomain> {
		return this.userDomains.filter(
			(userDomain: UserDomain) => userDomain.role === UserRole.CONTENT_MANAGER,
		);
	}

	@FormHasMany({
		isChanged: (initial: Array<UserDomain>, current: Array<UserDomain>) => {
			if (initial && current) {
				return !compareArrays(initial, current, {
					ignoreOrder: true,
					propertyFn: (item: UserDomain) => item?.domain.name || '',
				});
			}
			return false;
		},
	})
	@FormHasMany()
	public get communityManagerDomains(): Array<UserDomain> {
		return this.userDomains.filter(
			(userDomain: UserDomain) => userDomain.role === UserRole.COMMUNITY_MANAGER,
		);
	}

	@FormHasMany({
		isChanged: (initial: Array<UserDomain>, current: Array<UserDomain>) => {
			if (initial && current) {
				return !compareArrays(initial, current, {
					ignoreOrder: true,
					propertyFn: (item: UserDomain) => item?.domain.name || '',
				});
			}
			return false;
		},
	})
	@FormHasMany()
	public get readOnlyDomains(): Array<UserDomain> {
		return this.userDomains.filter(
			(userDomain: UserDomain) => userDomain.role === UserRole.READ_ONLY,
		);
	}

	public get displayNameAndEmail(): string {
		return `${this.name}`.concat(this.email ? ` (${this.email})` : '');
	}
}
