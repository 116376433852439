/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';

import { CountryProfile } from './country-profile.model';

import { Country } from 'app/custom-types/country/country.type';
import { Language } from 'app/enums/language.enum';
import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';

@JsonApiModelConfig({
	type: 'countryInfo',
	meta: ModelMetadata,
})
export class CountryInfo extends JsonApiModel {
	@Attribute({
		dataType: Country,
	})
	public code: Country;

	@Attribute()
	public supportedLanguages: Array<Language>;

	@BelongsTo()
	public countryProfile: CountryProfile;

	public get country(): Country {
		return this.code;
	}
}
