/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ModelConfig, HalModel, Attribute, HasOne } from 'ngx-hal';

import { NutrientValue } from '../classes/nutrient-value.class';
import { Nutrient } from './nutrient.hal.model';
import { NutrientUnit } from '../enums/nutrient-unit.enum';
import { EnergyNutrient } from '../classes/energy-nutrient.class';

@ModelConfig({
	type: 'RecipeNutritionInformation',
	endpoint: 'RecipeNutritionInformation',
})
export class RecipeNutritionInformation extends HalModel {
	@Attribute({
		useClass: NutrientValue,
	})
	public dailyValue: NutrientValue;

	@Attribute({
		useClass: EnergyNutrient,
	})
	public energyValue: EnergyNutrient;

	@Attribute()
	public unit: NutrientUnit;

	@Attribute()
	public value: number;

	@HasOne({
		propertyClass: Nutrient,
	})
	public nutrient: Nutrient;
}
