/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute } from 'ngx-hal';
import { Attribute as FormAttribute } from 'ngx-form-object';

import {
	transformLanguageForJsonApi,
	transformLanguageForHalApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { Language } from '../enums/language.enum';
import { TranslationStatusHal } from '../enums/translation-status.enum';
import { HalDatastoreModel } from './hal-datastore-model.model';

export class Translation extends HalDatastoreModel {
	@Attribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
	})
	@FormAttribute()
	public language: Language;

	@Attribute()
	@FormAttribute()
	public status: TranslationStatusHal;

	@Attribute()
	public modifiedAt: Date;

	public get isApproved(): boolean {
		return this.status === TranslationStatusHal.APPROVED;
	}
}
