/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute, BelongsTo as FormBelongsTo } from 'ngx-form-object';
import { Attribute, HalModel, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { HalDatastoreService } from '../services/datastore/hal-datastore.service';
import { Content } from './content.model';
import { CountryProfile } from './country-profile.hal.model';
import { Country } from './country.hal.model';
import { Profile } from './profile.hal.model';

@ModelConfig({
	type: 'Publication',
})
export class Publication extends HalModel<HalDatastoreService> {
	@Attribute()
	public numberOfComments: number;

	@Attribute()
	public numberOfFavourites: number;

	@Attribute()
	public numberOfViews: number;

	@Attribute()
	@FormAttribute()
	public usePreviousPublishedAt: boolean;

	@Attribute()
	@FormAttribute()
	public publishedAt: Date;

	@HasOne({
		propertyClass: Country,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public country: Country;

	@HasOne({
		propertyClass: 'ScheduledEvent',
		includeInPayload: true,
	})
	@FormBelongsTo()
	public scheduledEvent: 'ScheduledEvent';

	@HasOne({
		propertyClass: null,
		includeInPayload: true,
		// TODO ID: 6072 find a way to add propertyClass which will create announcement/collection...
	})
	@FormBelongsTo()
	public content: Content;

	/**
	 * @deprecated Used only for creating new publications. Use publisher instead.
	 */
	@HasOne({
		propertyClass: CountryProfile,
		includeInPayload: true,
	})
	public author: CountryProfile;

	@HasOne({
		propertyClass: Profile,
		includeInPayload: true,
	})
	public publisher: Profile;

	public get publisherId(): string {
		const publisherUrl: string = this.getRelationshipUrl('publisher');

		if (!publisherUrl) {
			return '';
		}

		const urlParts: Array<string> = publisherUrl.split('/');
		return urlParts[urlParts.length - 1];
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singlePublicationEndpoint,
			collectionEndpoint: this.datastore.rootApi.publicationCollectionEndpoint,
		};
	}
}
