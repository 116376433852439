/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasMany, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { DEFAULT_COUNTRY_PROFILE_NAME } from '../constants/default-county-profile-name.constant';
import { PHILIPS_PROFILE_NAME } from '../constants/philips-profile-name.constant';
import { ProfileStatus } from '../enums/consumer-status.enum';
import { Language } from '../enums/language.enum';
import { ProfileType } from '../enums/profile-type.enum';
import { IUserStats } from '../pages/admin/consumers/shared/interfaces/user-stats.interface';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { Comment } from './comment.hal.model';
import { Country } from './country.hal.model';
import { Device } from './device.hal.model';
import { Flag } from './flag.model';
import { HalDatastoreModel } from './hal-datastore-model.model';
import { Media } from './media.hal.model';
import { Product } from './product.model';
import { Recipe } from './recipe.hal.model';
import { User } from './user.hal.model';

@ModelConfig({
	type: 'Profile',
})
export class Profile extends HalDatastoreModel {
	@Attribute()
	// use displayName property
	public readonly name: string;

	@Attribute()
	public readonly description: string;

	@Attribute({
		externalName: 'type',
	})
	public profileType: ProfileType;

	@Attribute()
	public status: ProfileStatus;

	@Attribute()
	public stats: IUserStats;

	@Attribute()
	public createdAt: Date;

	@Attribute()
	public modifiedAt: Date;

	@Attribute()
	public lastLoggedAt: Date;

	@Attribute({
		externalName: 'interestedIn',
	})
	public deviceCategories: Array<string>;

	@Attribute()
	public timezone: string;

	@Attribute({
		transformResponseValue: (language: string) => transformLanguageForJsonApi(language),
		transformBeforeSave: (language: Language) => transformLanguageForHalApi(language),
	})
	public language: Language;

	@HasOne({ propertyClass: Media })
	public image: Media;

	@HasMany({
		itemsType: 'Recipe',
	})
	public recipes: Array<Recipe>;

	@HasOne({ propertyClass: Country })
	public country: Country;

	@HasOne({ propertyClass: User })
	public user: User;

	@HasMany({
		itemsType: Flag,
	})
	public flags: Array<Flag>;

	@HasMany({
		itemsType: 'Comment',
	})
	public comments: Array<Comment>;

	@HasMany({
		itemsType: 'Product',
	})
	public appliances: Array<Product>;

	public get devices(): Array<Device> {
		return this.appliances.map((product) => product.device);
	}

	/**
	 * @deprecated Deprecated on backend, use this.id because IDs are now aligned between v1 and v2
	 */
	public get countryProfileId(): string {
		if (!this.links || !this.links['profile:getProfileByUUID']) {
			return null;
		}

		return this.links['profile:getProfileByUUID'].href.replace('cid:', '');
	}

	public get isCountryUser(): boolean {
		return this.profileType === ProfileType.COUNTRY;
	}

	public profile; // Profile relationship is not implemented on the backend yet. Setting it manually is a workaround.

	// TODO move to profile relationship when relationship is implemented
	public get isDefaultProfileForItsCountry(): boolean {
		return (
			this.isCountryUser &&
			(this.name === PHILIPS_PROFILE_NAME || this.name === DEFAULT_COUNTRY_PROFILE_NAME)
		);
	}

	public get displayName(): string {
		if (this.isCountryUser && this.country) {
			return `${this.name} (${this.country.name})`;
		}

		return this.name;
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.profileCollectionEndpoint,
			singleResourceEndpoint: this.datastore.rootApi.singleProfileEndpoint,
		};
	}
}
