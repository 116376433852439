/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, ModelConfig } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { Translation } from './translation.model';

@ModelConfig({
	type: 'ProductTranslation',
	endpoint: 'ProductTranslation',
})
export class ProductTranslation extends Translation {
	@Attribute()
	@FormAttribute()
	public name: string;

	@Attribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
	})
	@FormAttribute()
	public language: Language;
}
