/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Inject, Optional, SkipSelf } from '@angular/core';
import { CacheStrategy, DatastoreConfig, DatastoreService } from 'ngx-hal';
import { APP_CONFIG, IAppConfig } from '../../app.config';
import { HalPagination } from '../../classes/pagination';
import { QueryParamsEncoder } from '../../classes/query-params-encoder';
import { CacheableStorage } from '../../classes/storage/cacheable-storage';
import { Accessory } from '../../models/accessory.hal.model';
import { Announcement } from '../../models/announcement.hal.model';
import { ArticleStep } from '../../models/article-step.model';
import { Article } from '../../models/article.model';
import { AttributeConfig } from '../../models/attribute-config/attribute-config.model';
import { AttributeOption } from '../../models/attribute-config/attribute-option.model';
import { CookingVariableAttributeOption } from '../../models/attribute-config/cooking-variable-attribute-option.model';
import { Author } from '../../models/author.model';
import { Category } from '../../models/category.model';
import { Comment } from '../../models/comment.hal.model';
import { CookingVariable } from '../../models/cooking-variable.model';
import { Device } from '../../models/device.hal.model';
import { FoodCompositionForFood } from '../../models/food-composition-for-food.model';
import { Food } from '../../models/food.model';
import { PmsRootApi } from '../../models/pms-root-api.model';
import { Product } from '../../models/product.model';
import { RecipeStep } from '../../models/recipe-step.model';
import { Recipe } from '../../models/recipe.hal.model';
import { RootApi } from '../../models/root-api.model';
import { ScheduledEvent } from '../../models/scheduled-event.model';
import { Tool } from '../../models/tool.model';

@DatastoreConfig({
	network: {
		globalRequestOptions: {
			headers: {
				Accept: 'application/vnd.oneka.v2.0+json',
			},
		},
	},
	paginationClass: HalPagination,
	storage: new CacheableStorage(),
	cacheStrategy: CacheStrategy.CUSTOM,
})
export class HalDatastoreService extends DatastoreService {
	protected httpParamsOptions = {
		encoder: new QueryParamsEncoder(),
	};

	public modelTypes = [
		Accessory,
		Announcement,
		Article,
		ArticleStep,
		AttributeConfig,
		AttributeOption,
		Author,
		Category,
		Comment,
		CookingVariable,
		CookingVariableAttributeOption,
		Device,
		Food,
		FoodCompositionForFood,
		Product,
		Recipe,
		RecipeStep,
		Tool,
		ScheduledEvent,
	];

	constructor(
		public httpClient: HttpClient,
		@Inject(APP_CONFIG) private readonly appConfig: IAppConfig,
		@Optional() @SkipSelf() parentModule: HalDatastoreService,
	) {
		super(httpClient);

		if (parentModule) {
			throw new Error('HalDatastoreService is already loaded. Import it in the AppModule only');
		}

		this.networkConfig.baseUrl = this.appConfig.apiv2.host;
		this.networkConfig.endpoint = this.appConfig.apiv2.endpoint;
	}

	public rootApi: RootApi;

	public pmsRootApi: PmsRootApi;
}
