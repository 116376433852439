/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute, BelongsTo, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HalDocument, HalModel, HasMany, HasOne } from 'ngx-hal';
import { HalPagination } from '../classes/pagination';
import { ContentStatusHal } from '../enums/content-status.enum';
import { CountryCode } from '../enums/country-code.enum';
import { Language } from '../enums/language.enum';
import { UnitSystemSlug } from '../enums/unit-system.enum';
import { getTranslatableProperty } from '../utils/helpers/get-translatable-property/get-translatable-property.helper';
import { transformLanguageForJsonApi } from '../utils/helpers/language-transformer/language-transformer.helper';
import { Author } from './author.model';
import { ContentMetrics } from './content-metrics.model';
import { Country } from './country.hal.model';
import { Flag } from './flag.model';
import { HalDatastoreModel } from './hal-datastore-model.model';
import { History } from './history.model';
import { ManagingCountry } from './managing-country.hal.model';
import { Publication } from './publication.model';
import { Translation } from './translation.model';
import { ContentDraftStatusReason } from '../enums/content-draft-status-reason.enum';

export abstract class Content extends HalDatastoreModel {
	@HasMany({
		itemsType: Translation,
	})
	@FormHasMany()
	public translations: Array<Translation>;

	@Attribute()
	@FormAttribute()
	public status: ContentStatusHal;

	@Attribute()
	public statusReason: ContentDraftStatusReason;

	@Attribute()
	public managed: boolean;

	@Attribute()
	public shortId: string;

	@Attribute()
	public title: string;

	@Attribute()
	public createdAt: Date;

	@Attribute()
	public modifiedAt: Date;

	@HasMany({
		itemsType: ManagingCountry,
	})
	@FormHasMany()
	public managingCountries: Array<ManagingCountry>;

	@HasMany({
		itemsType: Publication,
	})
	@FormHasMany()
	public publications: Array<Publication>;

	@HasMany({
		itemsType: History,
	})
	public history: Array<History>;

	// Remove when issue #129 is fixed in the ngx-hal
	@HasMany({
		itemsType: Comment,
	})
	public comments: Array<Comment>;

	@HasOne({
		propertyClass: null,
	})
	@BelongsTo()
	public defaultTranslation: Translation;

	@HasOne({ propertyClass: Author })
	public author: Author;

	@HasOne({ propertyClass: ContentMetrics, externalName: 'metrics' })
	public contentMetrics: ContentMetrics;

	@HasMany({
		itemsType: Flag,
	})
	public flags: Array<Flag>;

	public abstract displayNameTranslationKey: string;

	public get countries(): Array<Country> {
		return this.managingCountries
			.map((managingCountry: ManagingCountry) => managingCountry.country)
			.filter(Boolean);
	}

	public get isGlobal(): boolean {
		return Boolean(this.countries.length && this.countries[0].code === CountryCode.GLOBAL);
	}

	public get isLive(): boolean {
		return this.status === ContentStatusHal.LIVE || this.status === ContentStatusHal.LIVE_FLAGGED;
	}

	public get isDraft(): boolean {
		return this.status === ContentStatusHal.DRAFT;
	}

	public get isFlagged(): boolean {
		return this.status === ContentStatusHal.LIVE_FLAGGED;
	}

	public get isPremium(): boolean {
		return Boolean(this.links['premiums']);
	}

	public get isPublished(): boolean {
		return this.publications.length > 0;
	}

	public get displayLanguage(): Language {
		if (!this.rawResponse || !this.rawResponse.headers) {
			return null;
		}

		const language: Language = this.rawResponse.headers.get('content-language') as Language;

		if (language) {
			return transformLanguageForJsonApi(language) as Language;
		}

		return language;
	}

	public get defaultUnitSystem(): UnitSystemSlug {
		const country: Country = this.countries.length ? this.countries[0] : null;

		if (!country) {
			return UnitSystemSlug.METRIC;
		}

		return country.unitSystem || UnitSystemSlug.METRIC;
	}

	public getTranslatableProperty(language: Language, translatablePropertyKey: string): string {
		return getTranslatableProperty(this, translatablePropertyKey, language);
	}

	public getRelationshipTotalElements(relationshipName: string): number {
		const document: HalDocument<HalModel> = this.getRelationship(
			relationshipName,
		) as HalDocument<HalModel>;
		const pagination: HalPagination = document?.pagination as HalPagination;
		return pagination?.totalElements || 0;
	}
}
