/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from './hal-datastore-model.model';

@ModelConfig({
	type: 'ProductMetrics',
})
export class ProductMetrics extends HalDatastoreModel {
	@Attribute()
	public numberOfArticles: number;

	@Attribute()
	public numberOfRecipes: number;

	@Attribute()
	public numberOfCountries: number;

	@Attribute()
	public numberOfTranslations: number;

	@Attribute()
	public numberOfLinkedProducts: number;

	@Attribute()
	public numberOfUsers: number;
}
