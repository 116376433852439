/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ModelConfig, HalModel, HasMany, Attribute } from 'ngx-hal';

import { RecipeNutritionInformation } from './recipe-nutrition-information.model';

@ModelConfig({
	type: 'RecipeNutrition',
	endpoint: 'RecipeNutrition',
})
export class RecipeNutrition extends HalModel {
	@Attribute()
	public calculatedAt: Date;

	@Attribute()
	public recalculationInProgress: boolean;

	@HasMany({
		itemsType: RecipeNutritionInformation,
	})
	public calorieBreakdown: Array<RecipeNutritionInformation>;

	@HasMany({
		itemsType: RecipeNutritionInformation,
	})
	public energyBreakdown: Array<RecipeNutritionInformation>;

	@HasMany({
		itemsType: RecipeNutritionInformation,
	})
	public nutrientsBreakdown: Array<RecipeNutritionInformation>;

	// This is needed to create a unique RecipeNutrition model for each perServing option
	// related to: https://nutriu.atlassian.net/browse/NUT-23145
	public getUniqueModelIdentificator(): string {
		const identificator: string = super.getUniqueModelIdentificator();

		const valuePer: string = this.rawResponse.url.match(/valuePer=([\w]+)/i)[0] || '';

		return valuePer ? identificator.concat('&').concat(valuePer) : identificator;
	}
}
