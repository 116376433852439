/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HasOne, ModelConfig } from 'ngx-hal';
import { ModelConstructor } from 'ngx-hal/lib/types/model-constructor.type';
import { cleanLink } from '../../utils/helpers/helpers';
import { Accessory } from '../accessory.hal.model';
import { Category } from '../category.model';
import { CookingMethod } from '../cooking-method.model';
import { Device } from '../device.hal.model';
import { Product } from '../product.model';
import { AttributeOptionDummyValue } from './attribute-option-dummy-value.model';
import { AttributeOption } from './attribute-option.model';

@ModelConfig({
	type: 'AttributeOption',
	// endpoint is empty, because we'll fetch this resource only as a relationship on the Attribute resource
	endpoint: '',
})
export class CookingVariableAttributeOption extends AttributeOption {
	public static modelType = 'CookingVariableAttributeOption';

	@HasOne({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		propertyClass: (raw: any): ModelConstructor<any> => {
			// eslint-disable-next-line no-underscore-dangle
			const href: string = cleanLink(raw?._links?.self?.href);
			if (href?.match(/\/Product\/[\d\w-]+$/)) {
				return Product;
			} else if (href?.match(/\/Accessory\/[\d\w-]+$/)) {
				return Accessory;
			} else if (href?.match(/\/Device\/[\d\w-]+$/)) {
				return Device;
			} else if (href?.match(/\/CookingMethod\??/)) {
				return CookingMethod;
			} else if (href?.match(/\/Category\??/)) {
				return Category;
			}

			return AttributeOptionDummyValue;
		},
	})
	public value: unknown;
}
