/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ModelConfig, ModelEndpoints } from 'ngx-hal';
import { Product } from './product.model';

@ModelConfig({
	type: 'Tool',
})
export class Tool extends Product {
	public static modelType = 'Tool';
	public readonly isTool = true;
	public readonly isDevice = false;
	public readonly isConnectable = false;

	public get displayName(): string {
		return this.name;
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.toolCollectionEndpoint,
			singleResourceEndpoint: this.datastore.rootApi.singleToolEndpoint,
		};
	}
}
