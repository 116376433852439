/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum HighLevelCategoryEnum {
	ACCESSORIES = 'ACCESSORIES',
	ACCESSORY_PRODUCT = 'ACCESSORY_PRODUCT',
	AIR_COOKER_RECIPE_TYPE = 'AIR_COOKER_RECIPE_TYPE',
	ANNOUNCEMENT_CONTENT_CONTAINER_TYPE = 'ANNOUNCEMENT_CONTENT_CONTAINER_TYPE',
	ONBOARDING_ANNOUNCEMENT_CONTENT_CONTAINER_TYPE = 'ONBOARDING_ANNOUNCEMENT_CONTENT_CONTAINER_TYPE',
	SHOP_ANNOUNCEMENT_CONTENT_CONTAINER_TYPE = 'SHOP_ANNOUNCEMENT_CONTENT_CONTAINER_TYPE',
	ARTICLE_TYPE = 'ARTICLE_TYPE',
	COLLECTION = 'COLLECTION',
	CONTENT_CONTAINER_TYPE = 'CONTENT_CONTAINER_TYPE',
	DEVICE = 'DEVICE',
	DEVICE_ATTRIBUTE_COOKING_PROFILE = 'DEVICE_ATTRIBUTE_COOKING_PROFILE',
	DEVICE_CATEGORY_GROUP = 'PRODUCT_GROUP',
	DEVICE_GROUP = 'DEVICE_GROUP',
	DEVICE_PRODUCT = 'DEVICE_PRODUCT',
	EASY_TO_START_WITH = 'EASY_TO_START_WITH',
	HIGHLIGHT_CONTENT_CONTAINER_TYPE = 'HIGHLIGHT_CONTENT_CONTAINER_TYPE',
	INGREDIENT = 'INGREDIENT',
	IS_EASY = 'IS_EASY',
	NUTRIMAX_DEVICE_CONFIG = 'NUTRIMAX_DEVICE_CONFIG',
	PACK_HOLDER = 'PACK_HOLDER',
	PHILIPS_COUNTRY = 'PHILIPS_COUNTRY',
	PINNED_HOLDER = 'PINNED_HOLDER',
	PREHEAT = 'PREHEAT',
	PRODUCT_RECIPE_BOOK = 'PRODUCT_RECIPE_BOOK',
	PUSH_MESSAGE_APPLIANCE_TYPE = 'PUSH_MESSAGE_APPLIANCE_TYPE',
	PUSH_MESSAGE_APPLIANCE_TYPE_ALL_USERS = 'PUSH_MESSAGE_APPLIANCE_TYPE_ALL_USERS',
	PUSH_MESSAGE_TYPE = 'PUSH_MESSAGE_TYPE',
	RECIPE_BOOK = 'RECIPE_BOOK',
	STARTER_PACK = 'STARTER_PACK',
	VENUS_DEVICE_CONFIG = 'VENUS_DEVICE_CONFIG',
}
