/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/models/user.model';
import { AuthService } from 'app/services/auth/auth.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

export type HasCurrentUserPermissionsFunction = (user: User) => Observable<boolean> | boolean;

@Injectable()
export class AccessService {
	public constructor(private readonly authService: AuthService, private readonly router: Router) {}

	public hasCurrentUserAccess(
		hasPermissions: HasCurrentUserPermissionsFunction,
		redirectTo403 = false,
	): Observable<boolean> {
		const hasAccess$ = new ReplaySubject<boolean>();

		this.authService.fetchCurrentUser().subscribe(() => {
			const canAccess: Observable<boolean> | boolean = hasPermissions(this.authService.currentUser);

			const accessGranted$ =
				canAccess instanceof Observable ? canAccess : new BehaviorSubject<boolean>(canAccess);
			accessGranted$.subscribe((accessGranted: boolean) => {
				if (!accessGranted && redirectTo403) {
					this.router.navigate(['/admin/access-denied']);
					hasAccess$.next(true);
					hasAccess$.complete();
					return;
				}

				hasAccess$.next(accessGranted);
				hasAccess$.complete();
			});
		});

		return hasAccess$;
	}
}
