/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute, BelongsTo as FormBelongsTo } from 'ngx-form-object';
import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { Country } from './country.hal.model';
import { HalDatastoreModel } from './hal-datastore-model.model';

@ModelConfig({
	type: 'VariantAvailability',
})
export class VariantAvailability extends HalDatastoreModel {
	@Attribute()
	@FormAttribute()
	public prxLocale: string;

	@HasOne({
		propertyClass: Country,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public country: Country;
}
