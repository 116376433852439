/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo as FormBelongsTo } from 'ngx-form-object';
import { HalModel, HasOne, ModelConfig } from 'ngx-hal';

import { Content } from './content.model';
import { Country } from './country.hal.model';

@ModelConfig({
	type: 'ManagingCountry',
	endpoint: 'Content/Country',
})
export class ManagingCountry extends HalModel {
	@HasOne({
		propertyClass: Country,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public country: Country;

	@HasOne({
		propertyClass: null,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public content: Content;

	public get endpoint(): string {
		return `Content/${this.content.id}/Country`;
	}
}
