/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig } from 'ngx-hal';

import { ExtendedFormArray } from 'ngx-form-object';
import { RecipeStepOperation } from '../enums/recipe-step-operation.enum';

import { RecipeIngredientFormStore } from '../forms/recipe-ingredient/recipe-ingredient.form-store';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { Category } from './category.model';
import { Content } from './content.model';
import { CookingVariable } from './cooking-variable.model';
import { HalDatastoreModel } from './hal-datastore-model.model';
import { Media } from './media.hal.model';
import { RecipeIngredient } from './recipe-ingredient.hal.model';
import { RecipeStepProduct } from './recipe-step-product.model';
import { RecipeStepTranslation } from './recipe-step-translation.model';

@ModelConfig({
	type: 'RecipeStep',
})
export class RecipeStep extends HalDatastoreModel {
	public static modelType = 'RecipeStep';
	public static ingredientsRelationshipName = 'ingredients';

	@Attribute()
	@FormAttribute()
	public speed: number;

	@Attribute()
	@FormAttribute()
	public operation: RecipeStepOperation;

	@HasOne({
		propertyClass: Media,
		externalName: 'image',
		includeInPayload: true,
	})
	@BelongsTo()
	public stepImage: Media;

	@HasMany({
		itemsType: RecipeStepTranslation,
	})
	@FormHasMany()
	public translations: Array<RecipeStepTranslation>;

	@BelongsTo()
	public deviceCategory: Category;

	@HasMany({
		itemsType: RecipeStepProduct,
	})
	@FormHasMany()
	public products: Array<RecipeStepProduct>;

	@FormHasMany({
		isChanged: (initial: Array<RecipeStepProduct>, current: Array<RecipeStepProduct>) => {
			if (!initial && !current) {
				return false;
			}

			if (!initial || !current) {
				return true;
			}

			return !compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: RecipeStepProduct) => item?.tool?.id || '',
			});
		},
	})
	// For now, we support only Tools, but Devices and Accessories can be added in the future
	public get tools(): Array<RecipeStepProduct> {
		return (this.products || []).filter((product: RecipeStepProduct) => product.links.tool);
	}
	public set tools(_value: Array<RecipeStepProduct>) {
		// Do nothing
	}

	@HasMany({
		itemsType: RecipeIngredient,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (
			initial: Array<RecipeIngredient>,
			current: Array<RecipeIngredient>,
			recipeIngredients: ExtendedFormArray,
		) => {
			// Check the list of ingredients specifically because `recipeIngredients` also has other
			// properties including references to the `step` and `recipe` which causes infinite loop.
			const isArrayItemChanged: boolean = recipeIngredients.controls.some(
				(item: RecipeIngredientFormStore) => item.isChanged,
			);
			return (
				isArrayItemChanged ||
				!compareArrays(initial, current, {
					ignoreOrder: false,
					propertyFn: (item) => item.ingredient.id,
				})
			);
		},
	})
	public ingredients: Array<RecipeIngredient>;

	@HasOne({
		propertyClass: null,
	})
	public parent: Content;

	@HasMany({
		itemsType: CookingVariable,
		includeInPayload: true,
	})
	@FormHasMany()
	public cookingVariables: Array<CookingVariable>;

	@HasOne({
		propertyClass: Media,
		includeInPayload: true,
	})
	@BelongsTo()
	public video: Media;

	public get isProcessingStep(): boolean {
		return this.operation === RecipeStepOperation.PROCESSING;
	}

	public displayNameTranslationKey = 'recipeStep';

	// Cooking variables are fetched in a flat manner and yet they can be hierarchical.
	// This method restructures the cooking variables to a parent/child structure.
	// Cooking profiles are the example of this where profiles are children of the parent cooking variable.
	public restructureCookingVariables(): void {
		this.cookingVariables.forEach((cookingVariable: CookingVariable) => {
			cookingVariable.cookingVariables = this.cookingVariables.filter(
				({ parent }: CookingVariable) => parent?.id === cookingVariable.id,
			);
		});

		this.cookingVariables = this.cookingVariables.filter(({ parent }: CookingVariable) => !parent);
	}
}
