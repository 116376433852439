/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	ExtendedFormArray,
	ExtendedFormControl,
	Attribute as FormAttribute,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import { Attribute, HalModel, ModelConfig } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import { HalDatastoreService } from '../services/datastore/hal-datastore.service';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';

@ModelConfig({
	type: 'LanguageConfig',
})
export class LanguageConfig extends HalModel<HalDatastoreService> {
	@Attribute()
	@FormAttribute()
	public language: Language;

	@Attribute()
	@FormAttribute()
	public languageOfOrigin: Language;

	@Attribute()
	@FormHasMany({
		isChanged: (
			initial: Array<Language>,
			current: Array<Language>,
			formArray: ExtendedFormArray,
		) => {
			const isArrayItemChanged: boolean = (formArray.controls as Array<ExtendedFormControl>).some(
				(item) => item && item.isChanged,
			);
			return (
				isArrayItemChanged ||
				!compareArrays(initial, current, {
					ignoreOrder: false,
				})
			);
		},
	})
	public fallbacks: Array<Language>;
}
