/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ILanguageData } from '../custom-types/language-data.type';
import { Locale } from './locale.enum';

export enum Language {
	ALBANIAN = 'sq_AL',
	ARABIC_BAHRAIN = 'ar_BH',
	ARABIC_JORDAN = 'ar_JO',
	ARABIC_KUWAIT = 'ar_KW',
	ARABIC_OMAN = 'ar_OM',
	ARABIC_QATAR = 'ar_QA',
	ARABIC_SAUDI_ARABIA = 'ar_SA',
	ARABIC = 'ar_AE',
	BAHASA_INDONESIA = 'id_ID',
	BULGARIAN = 'bg_BG',
	CHINESE = 'zh_CN',
	CHINESE_HONG_KONG = 'zh_HK',
	CHINESE_MACAU = 'zh_MO',
	CHINESE_TAIWAN = 'zh_TW',
	CROATIAN = 'hr_HR',
	CZECH = 'cs_CZ',
	DANISH = 'da_DK',
	DUTCH_BELGIUM = 'nl_BE',
	DUTCH = 'nl_NL',
	ENGLISH = 'en_GB',
	ENGLISH_AUSTRALIA = 'en_AU',
	ENGLISH_INDIA = 'en_IN',
	ENGLISH_NEW_ZEALAND = 'en_NZ',
	ENGLISH_PHILIPPINES = 'en_PH',
	ENGLISH_SINGAPORE = 'en_SG',
	ENGLISH_SOUTH_AFRICA = 'en_ZA',
	ENGLISH_UNITED_STATES = 'en_US',
	ESTONIAN = 'et_EE',
	FINNISH = 'fi_FI',
	FRENCH = 'fr_FR',
	FRENCH_BELGIUM = 'fr_BE',
	FRENCH_CANADA = 'fr_CA',
	FRENCH_LUX = 'fr_LU',
	FRENCH_MAURITIUS = 'fr_MU',
	FRENCH_SWITZERLAND = 'fr_CH',
	GERMAN = 'de_DE',
	GERMAN_AUSTRIA = 'de_AT',
	GERMAN_LUX = 'de_LU',
	GERMAN_SWITZERLAND = 'de_CH',
	GREEK = 'el_GR',
	GREEK_CYPRUS = 'el_CY',
	HEBREW = 'he_IL',
	HINDI_INDIA = 'hi_IN',
	HUNGARIAN = 'hu_HU',
	ITALIAN = 'it_IT',
	ITALIAN_SWITZERLAND = 'it_CH',
	KOREAN = 'ko_KR',
	LATVIAN = 'lv_LV',
	LITHUANIAN = 'lt_LT',
	MACEDONIAN = 'mk_MK',
	NORWEGIAN = 'nb_NO',
	POLISH = 'pl_PL',
	PORTUGUESE = 'pt_PT',
	PORTUGUESE_BRAZIL = 'pt_BR',
	ROMANIAN = 'ro_RO',
	RUSSIAN = 'ru_RU',
	RUSSIAN_UKRAINE = 'ru_UA',
	SERBIAN = 'sr_RS',
	SLOVAK = 'sk_SK',
	SLOVENIAN = 'sl_SI',
	SPANISH = 'es_ES',
	SPANISH_ARGENTINA = 'es_AR',
	SPANISH_CHILE = 'es_CL',
	SWEDISH = 'sv_SE',
	THAI = 'th_TH',
	TURKISH = 'tr_TR',
	UKRAINIAN = 'uk_UA',
	VIETNAMESE = 'vi_VN',
}

const selectableLanguagesEU: Array<Language> = [
	Language.ALBANIAN,
	Language.ARABIC_BAHRAIN,
	Language.ARABIC_JORDAN,
	Language.ARABIC_KUWAIT,
	Language.ARABIC_OMAN,
	Language.ARABIC_QATAR,
	Language.ARABIC_SAUDI_ARABIA,
	Language.ARABIC,
	Language.BAHASA_INDONESIA,
	Language.BULGARIAN,
	Language.CHINESE,
	Language.CHINESE_HONG_KONG,
	Language.CHINESE_MACAU,
	Language.CHINESE_TAIWAN,
	Language.CROATIAN,
	Language.CZECH,
	Language.DANISH,
	Language.DUTCH_BELGIUM,
	Language.DUTCH,
	Language.ENGLISH_AUSTRALIA,
	Language.ENGLISH_INDIA,
	Language.ENGLISH_NEW_ZEALAND,
	Language.ENGLISH_PHILIPPINES,
	Language.ENGLISH_SINGAPORE,
	Language.ENGLISH_SOUTH_AFRICA,
	Language.ENGLISH_UNITED_STATES,
	Language.ENGLISH,
	Language.ESTONIAN,
	Language.FINNISH,
	Language.FRENCH_BELGIUM,
	Language.FRENCH_CANADA,
	Language.FRENCH_MAURITIUS,
	Language.FRENCH_SWITZERLAND,
	Language.FRENCH,
	Language.GERMAN_AUSTRIA,
	Language.GERMAN_SWITZERLAND,
	Language.GERMAN,
	Language.GREEK,
	Language.GREEK_CYPRUS,
	Language.HEBREW,
	Language.HINDI_INDIA,
	Language.HUNGARIAN,
	Language.ITALIAN,
	Language.KOREAN,
	Language.LATVIAN,
	Language.LITHUANIAN,
	Language.MACEDONIAN,
	Language.NORWEGIAN,
	Language.POLISH,
	Language.PORTUGUESE_BRAZIL,
	Language.PORTUGUESE,
	Language.ROMANIAN,
	Language.RUSSIAN,
	Language.RUSSIAN_UKRAINE,
	Language.SERBIAN,
	Language.SLOVAK,
	Language.SLOVENIAN,
	Language.SPANISH,
	Language.SPANISH_ARGENTINA,
	Language.SPANISH_CHILE,
	Language.SWEDISH,
	Language.THAI,
	Language.TURKISH,
	Language.UKRAINIAN,
	Language.VIETNAMESE,
];

export const selectableUnsupportedLanguages: Array<Language> = [
	Language.ARABIC_SAUDI_ARABIA,
	Language.FRENCH_SWITZERLAND,
	Language.ITALIAN_SWITZERLAND,
];

export const selectableLanguages: Array<Language> = selectableLanguagesEU;

export const languageData: Record<string, ILanguageData> = {
	[Language.ALBANIAN]: {
		id: Language.ALBANIAN,
		label: 'Albanian',
		locale: Locale.ALBANIAN,
		priority: 60,
	},
	[Language.ARABIC_BAHRAIN]: {
		id: Language.ARABIC_BAHRAIN,
		label: 'Arabic (Bahrain)',
		locale: Locale.ARABIC_BAHRAIN,
		priority: 60,
	},
	[Language.ARABIC_JORDAN]: {
		id: Language.ARABIC_JORDAN,
		label: 'Arabic (Jordan)',
		locale: Locale.ARABIC_JORDAN,
		priority: 60,
	},
	[Language.ARABIC_KUWAIT]: {
		id: Language.ARABIC_KUWAIT,
		label: 'Arabic (Kuwait)',
		locale: Locale.ARABIC_KUWAIT,
		priority: 60,
	},
	[Language.ARABIC_OMAN]: {
		id: Language.ARABIC_OMAN,
		label: 'Arabic (Oman)',
		locale: Locale.ARABIC_OMAN,
		priority: 60,
	},
	[Language.ARABIC_QATAR]: {
		id: Language.ARABIC_QATAR,
		label: 'Arabic (Qatar)',
		locale: Locale.ARABIC_QATAR,
		priority: 60,
	},
	[Language.ARABIC_SAUDI_ARABIA]: {
		id: Language.ARABIC_SAUDI_ARABIA,
		label: 'Arabic (Saudi Arabia)',
		locale: Locale.ARABIC_SAUDI_ARABIA,
		priority: 60,
	},
	[Language.ARABIC]: {
		id: Language.ARABIC,
		label: 'Arabic',
		locale: Locale.ARABIC,
		priority: 60,
	},
	[Language.BAHASA_INDONESIA]: {
		id: Language.BAHASA_INDONESIA,
		label: 'Bahasa Indonesia',
		locale: Locale.BAHASA_INDONESIA,
		priority: 60,
	},
	[Language.BULGARIAN]: {
		id: Language.BULGARIAN,
		label: 'Bulgarian',
		locale: Locale.BULGARIAN,
		priority: 30,
	},
	[Language.CHINESE]: {
		id: Language.CHINESE,
		label: 'Chinese',
		locale: Locale.CHINESE,
		priority: 60,
	},
	[Language.CHINESE_HONG_KONG]: {
		id: Language.CHINESE_HONG_KONG,
		label: 'Chinese, Traditional (Hong Kong)',
		locale: Locale.CHINESE_HONG_KONG,
		priority: 60,
	},
	[Language.CHINESE_MACAU]: {
		id: Language.CHINESE_MACAU,
		label: 'Chinese, Traditional (Macau)',
		locale: Locale.CHINESE_MACAU,
		priority: 60,
	},
	[Language.CHINESE_TAIWAN]: {
		id: Language.CHINESE_TAIWAN,
		label: 'Chinese, Traditional (Taiwan)',
		locale: Locale.CHINESE_TAIWAN,
		priority: 60,
	},
	[Language.CROATIAN]: {
		id: Language.CROATIAN,
		label: 'Croatian',
		locale: Locale.CROATIAN,
		priority: 30,
	},
	[Language.CZECH]: {
		id: Language.CZECH,
		label: 'Czech',
		locale: Locale.CZECH,
		priority: 30,
	},
	[Language.DANISH]: {
		id: Language.DANISH,
		label: 'Danish',
		locale: Locale.DANISH,
		priority: 30,
	},
	[Language.DUTCH_BELGIUM]: {
		id: Language.DUTCH_BELGIUM,
		label: 'Dutch (Belgium)',
		locale: Locale.DUTCH_BELGIUM,
		priority: 22,
	},
	[Language.DUTCH]: {
		id: Language.DUTCH,
		label: 'Dutch',
		locale: Locale.DUTCH,
		priority: 22,
	},
	[Language.ENGLISH]: {
		id: Language.ENGLISH,
		label: 'English',
		locale: Locale.ENGLISH,
		priority: 10,
	},
	[Language.ENGLISH_AUSTRALIA]: {
		id: Language.ENGLISH_AUSTRALIA,
		label: 'English (Australia)',
		locale: Locale.ENGLISH_AUSTRALIA,
		priority: 12,
	},
	[Language.ENGLISH_INDIA]: {
		id: Language.ENGLISH_INDIA,
		label: 'English (India)',
		locale: Locale.ENGLISH_INDIA,
		priority: 60,
	},
	[Language.ENGLISH_NEW_ZEALAND]: {
		id: Language.ENGLISH_NEW_ZEALAND,
		label: 'English (New Zealand)',
		locale: Locale.ENGLISH_NEW_ZEALAND,
		priority: 12,
	},
	[Language.ENGLISH_PHILIPPINES]: {
		id: Language.ENGLISH_PHILIPPINES,
		label: 'English (Philippines)',
		locale: Locale.ENGLISH_PHILIPPINES,
		priority: 12,
	},
	[Language.ENGLISH_SINGAPORE]: {
		id: Language.ENGLISH_SINGAPORE,
		label: 'English (Singapore)',
		locale: Locale.ENGLISH_SINGAPORE,
		priority: 12,
	},
	[Language.ENGLISH_SOUTH_AFRICA]: {
		id: Language.ENGLISH_SOUTH_AFRICA,
		label: 'English (South Africa)',
		locale: Locale.ENGLISH_SOUTH_AFRICA,
		priority: 12,
	},
	[Language.ENGLISH_UNITED_STATES]: {
		id: Language.ENGLISH_UNITED_STATES,
		label: 'English (United States)',
		locale: Locale.ENGLISH_UNITED_STATES,
		priority: 12,
	},
	[Language.ESTONIAN]: {
		id: Language.ESTONIAN,
		label: 'Estonian',
		locale: Locale.ESTONIAN,
		priority: 30,
	},
	[Language.FINNISH]: {
		id: Language.FINNISH,
		label: 'Finnish',
		locale: Locale.FINNISH,
		priority: 30,
	},
	[Language.FRENCH]: {
		id: Language.FRENCH,
		label: 'French',
		locale: Locale.FRENCH,
		priority: 30,
	},
	[Language.FRENCH_BELGIUM]: {
		id: Language.FRENCH_BELGIUM,
		label: 'French (Belgium)',
		locale: Locale.FRENCH_BELGIUM,
		priority: 40,
	},
	[Language.FRENCH_CANADA]: {
		id: Language.FRENCH_CANADA,
		label: 'French (Canada)',
		locale: Locale.FRENCH_CANADA,
		priority: 40,
	},
	[Language.FRENCH_LUX]: {
		id: Language.FRENCH_LUX,
		label: 'French (Luxemburg)',
		locale: Locale.FRENCH,
		priority: 40,
	},
	[Language.FRENCH_MAURITIUS]: {
		id: Language.FRENCH_MAURITIUS,
		label: 'French (Mauritius)',
		locale: Locale.FRENCH_MAURITIUS,
		priority: 40,
	},
	[Language.FRENCH_SWITZERLAND]: {
		id: Language.FRENCH_SWITZERLAND,
		label: 'French (Switzerland)',
		locale: Locale.FRENCH_SWITZERLAND,
		priority: 40,
	},
	[Language.GERMAN]: {
		id: Language.GERMAN,
		label: 'German',
		locale: Locale.GERMAN,
		priority: 20,
	},
	[Language.GERMAN_AUSTRIA]: {
		id: Language.GERMAN_AUSTRIA,
		label: 'German (Austria)',
		locale: Locale.GERMAN,
		priority: 22,
	},
	[Language.GERMAN_SWITZERLAND]: {
		id: Language.GERMAN_SWITZERLAND,
		label: 'German (Switzerland)',
		locale: Locale.GERMAN_SWITZERLAND,
		priority: 22,
	},
	[Language.GERMAN_LUX]: {
		id: Language.GERMAN_LUX,
		label: 'German (Luxembourg)',
		locale: Locale.GERMAN,
		priority: 22,
	},
	[Language.GREEK]: {
		id: Language.GREEK,
		label: 'Greek',
		locale: Locale.GREEK,
		priority: 30,
	},
	[Language.GREEK_CYPRUS]: {
		id: Language.GREEK_CYPRUS,
		label: 'Greek (Cyprus)',
		locale: Locale.GREEK_CYPRUS,
		priority: 30,
	},
	[Language.HEBREW]: {
		id: Language.HEBREW,
		label: 'Hebrew',
		locale: Locale.HEBREW,
		priority: 30,
	},
	[Language.HINDI_INDIA]: {
		id: Language.HINDI_INDIA,
		label: 'Hindi (India)',
		locale: Locale.HINDI_INDIA,
		priority: 30,
	},
	[Language.HUNGARIAN]: {
		id: Language.HUNGARIAN,
		label: 'Hungarian',
		locale: Locale.HUNGARIAN,
		priority: 30,
	},
	[Language.ITALIAN]: {
		id: Language.ITALIAN,
		label: 'Italian',
		locale: Locale.ITALIAN,
		priority: 30,
	},
	[Language.ITALIAN_SWITZERLAND]: {
		id: Language.ITALIAN_SWITZERLAND,
		label: 'Italian (Switzerland)',
		locale: Locale.ENGLISH,
		priority: 32,
	},
	[Language.KOREAN]: {
		id: Language.KOREAN,
		label: 'Korean',
		locale: Locale.KOREAN,
		priority: 30,
	},
	[Language.LATVIAN]: {
		id: Language.LATVIAN,
		label: 'Latvian',
		locale: Locale.LATVIAN,
		priority: 30,
	},
	[Language.LITHUANIAN]: {
		id: Language.LITHUANIAN,
		label: 'Lithuanian',
		locale: Locale.LITHUANIAN,
		priority: 30,
	},
	[Language.MACEDONIAN]: {
		id: Language.MACEDONIAN,
		label: 'Macedonian',
		locale: Locale.MACEDONIAN,
		priority: 60,
	},
	[Language.NORWEGIAN]: {
		id: Language.NORWEGIAN,
		label: 'Norwegian',
		locale: Locale.NORWEGIAN,
		priority: 30,
	},
	[Language.POLISH]: {
		id: Language.POLISH,
		label: 'Polish',
		locale: Locale.POLISH,
		priority: 30,
	},
	[Language.PORTUGUESE]: {
		id: Language.PORTUGUESE,
		label: 'Portuguese',
		locale: Locale.PORTUGUESE,
		priority: 30,
	},
	[Language.PORTUGUESE_BRAZIL]: {
		id: Language.PORTUGUESE_BRAZIL,
		label: 'Portuguese (Brazil)',
		locale: Locale.PORTUGUESE_BRAZIL,
		priority: 32,
	},
	[Language.ROMANIAN]: {
		id: Language.ROMANIAN,
		label: 'Romanian',
		locale: Locale.ROMANIAN,
		priority: 60,
	},
	[Language.RUSSIAN]: {
		id: Language.RUSSIAN,
		label: 'Russian',
		locale: Locale.RUSSIAN,
		priority: 60,
	},
	[Language.RUSSIAN_UKRAINE]: {
		id: Language.RUSSIAN_UKRAINE,
		label: 'Russian (Ukraine)',
		locale: Locale.RUSSIAN_UKRAINE,
		priority: 60,
	},
	[Language.SERBIAN]: {
		id: Language.SERBIAN,
		label: 'Serbian',
		locale: Locale.SERBIAN,
		priority: 30,
	},
	[Language.SLOVAK]: {
		id: Language.SLOVAK,
		label: 'Slovak',
		locale: Locale.SLOVAK,
		priority: 30,
	},
	[Language.SLOVENIAN]: {
		id: Language.SLOVENIAN,
		label: 'Slovenian',
		locale: Locale.SLOVENIAN,
		priority: 30,
	},
	[Language.SPANISH]: {
		id: Language.SPANISH,
		label: 'Spanish',
		locale: Locale.SPANISH,
		priority: 30,
	},
	[Language.SPANISH_ARGENTINA]: {
		id: Language.SPANISH_ARGENTINA,
		label: 'Spanish (Argentina)',
		locale: Locale.SPANISH_ARGENTINA,
		priority: 30,
	},
	[Language.SPANISH_CHILE]: {
		id: Language.SPANISH_CHILE,
		label: 'Spanish (Chile)',
		locale: Locale.SPANISH_CHILE,
		priority: 30,
	},
	[Language.SWEDISH]: {
		id: Language.SWEDISH,
		label: 'Swedish',
		locale: Locale.SWEDISH,
		priority: 30,
	},
	[Language.THAI]: {
		id: Language.THAI,
		label: 'Thai',
		locale: Locale.THAI,
		priority: 60,
	},
	[Language.TURKISH]: {
		id: Language.TURKISH,
		label: 'Turkish',
		locale: Locale.TURKISH,
		priority: 30,
	},
	[Language.UKRAINIAN]: {
		id: Language.UKRAINIAN,
		label: 'Ukrainian',
		locale: Locale.UKRAINIAN,
		priority: 30,
	},
	[Language.VIETNAMESE]: {
		id: Language.VIETNAMESE,
		label: 'Vietnamese',
		locale: Locale.VIETNAMESE,
		priority: 60,
	},
};

export const prioritizedSelectableLanguages: Array<Language> = []
	.concat(selectableLanguages)
	.sort(
		(language1: Language, language2: Language) =>
			languageData[language1].priority - languageData[language2].priority,
	);
