/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { HighLevelCategoryEnum } from '../enums/high-level-category.enum';
import { Language } from '../enums/language.enum';
import { PowerUnit } from '../enums/power-unit.enum';
import { PressureUnit } from '../enums/pressure-unit.enum';
import { TemperatureUnit } from '../enums/temperature-unit.enum';
import { IDeviceOptionLimits } from '../interfaces/device-config.interface';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { isCompactDevice } from '../utils/helpers/is-compact-device/is-compact-device.helper';
import { Accessory } from './accessory.hal.model';
import { AttributeConfig } from './attribute-config/attribute-config.model';
import { Category } from './category.model';
import { CookingMethod } from './cooking-method.model';
import { Product } from './product.model';

@ModelConfig({
	type: 'Device',
})
export class Device extends Product {
	public static modelType = 'Device';
	public displayNameTranslationKey = 'Device';
	public readonly isDevice = true;

	@Attribute()
	@FormAttribute()
	public range: string;

	@Attribute()
	@FormAttribute()
	public codename: string;

	@Attribute()
	public isConnectable: boolean;

	@Attribute()
	public time: Pick<IDeviceOptionLimits, 'max' | 'min'>;

	@Attribute()
	public temperature: Pick<
		IDeviceOptionLimits<TemperatureUnit>,
		'interval' | 'max' | 'min' | 'unit'
	>;

	@Attribute()
	public pressure: Pick<IDeviceOptionLimits<PressureUnit>, 'interval' | 'max' | 'min' | 'unit'>;

	// This property doesn't exist on backend model at the moment;
	// it's used for supporting some cooking methods for Rice cooker connected
	@Attribute()
	public power: IDeviceOptionLimits<PowerUnit>;

	@HasOne({
		propertyClass: 'AttributeConfig',
	})
	public attributeConfig: AttributeConfig;

	@HasMany({
		itemsType: 'Accessory',
	})
	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Accessory) => item.id,
			}),
	})
	public accessories: Array<Accessory>;

	@HasMany({
		itemsType: 'Accessory',
	})
	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Accessory) => item.id,
			}),
	})
	public preselectedAccessories: Array<Accessory>;

	@HasMany({
		itemsType: CookingMethod,
	})
	public cookingMethods: Array<CookingMethod>;

	public get isCompact(): boolean {
		return isCompactDevice(this.id);
	}

	public get displayName(): string {
		return this.deviceCtnLabel ? `${this.name} (${this.deviceCtnLabel})` : this.name;
	}

	public productGroupLabel(language: Language): string {
		const groupCategory: Category = this.categories.find(
			(category: Category) => category.parent?.parent?.slug === HighLevelCategoryEnum.DEVICE_GROUP,
		);
		if (!groupCategory) {
			return '';
		}

		return groupCategory.getDisplayName(language);
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.deviceCollectionEndpoint,
			singleResourceEndpoint: this.datastore.rootApi.singleDeviceEndpoint,
		};
	}
}
