/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HalModel, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { MediaSizes } from '../classes/media-sizes';
import { HalDatastoreService } from '../services/datastore/hal-datastore.service';

@ModelConfig({
	type: 'Media',
})
export class Media extends HalModel<HalDatastoreService> {
	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleMediaEndpoint,
			collectionEndpoint: this.datastore.rootApi.mediaCollectionEndpoint,
		};
	}

	@Attribute()
	@FormAttribute()
	public url: string;

	@Attribute({
		useClass: MediaSizes,
	})
	@FormAttribute()
	public availableSizes: MediaSizes;

	public file: File;
}
