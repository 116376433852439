/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HalModel, HasOne, ModelConfig } from 'ngx-hal';
import { ContentStatusHal } from '../enums/content-status.enum';
import { HistoryEvent } from '../enums/history-event.enum';
import { Author } from './author.model';

@ModelConfig({
	type: 'History',
})
export class History extends HalModel {
	@Attribute()
	public event: HistoryEvent;

	@Attribute()
	public createdAt: Date;

	@Attribute()
	public description: string;

	@Attribute()
	public comment: string;

	@HasOne({ propertyClass: Author })
	public author: Author;

	@Attribute()
	public currentStatus: ContentStatusHal;
}
