/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ContentType {
	ANNOUNCEMENT = 'ANNOUNCEMENT',
	ARTICLE = 'ARTICLE',
	HIGHLIGHT = 'HIGHLIGHT',
	PACK_HOLDER = 'PACK_HOLDER',
	PINNED_HOLDER = 'PINNED_HOLDER',
	PRODUCT_RECIPE_BOOK = 'PRODUCT_RECIPE_BOOK',
	PUSH_MESSAGE = 'PUSH_MESSAGE',
	RECIPE = 'RECIPE',
	RECIPE_BOOK = 'RECIPE_BOOK',
	ONBOARDING_ANNOUNCEMENT = 'ONBOARDING_ANNOUNCEMENT',
	SHOP_ANNOUNCEMENT = 'SHOP_ANNOUNCEMENT',
}

export interface IContentTypeData {
	color: string;
	id: ContentType;
	pluralTranslationKey: string;
	translationKey: string;
	urlKey: string;
}

export const contentTypeData: Record<ContentType, IContentTypeData> = {
	[ContentType.ANNOUNCEMENT]: {
		color: '#FF4173',
		id: ContentType.ANNOUNCEMENT,
		pluralTranslationKey: 'common.announcement.plural',
		translationKey: 'contentType.announcement',
		urlKey: 'announcements',
	},
	[ContentType.ONBOARDING_ANNOUNCEMENT]: {
		color: '#5E503F',
		id: ContentType.ONBOARDING_ANNOUNCEMENT,
		pluralTranslationKey: 'common.onboardingAnnouncement.plural',
		translationKey: 'contentType.onboardingAnnouncement',
		urlKey: 'onboarding-announcements',
	},
	[ContentType.SHOP_ANNOUNCEMENT]: {
		color: '#638475',
		id: ContentType.SHOP_ANNOUNCEMENT,
		pluralTranslationKey: 'common.shopAnnouncement.plural',
		translationKey: 'contentType.shopAnnouncement',
		urlKey: 'shop-announcements',
	},
	[ContentType.ARTICLE]: {
		color: '#B972BF',
		id: ContentType.ARTICLE,
		pluralTranslationKey: 'common.nutriUStory.plural',
		translationKey: 'contentType.tip',
		urlKey: 'articles',
	},
	[ContentType.HIGHLIGHT]: {
		color: '#56445D',
		id: ContentType.HIGHLIGHT,
		pluralTranslationKey: 'common.highlight.plural',
		translationKey: 'contentType.highlight',
		urlKey: 'highlights',
	},
	[ContentType.RECIPE]: {
		color: '#438BCC',
		id: ContentType.RECIPE,
		pluralTranslationKey: 'common.recipe.plural',
		translationKey: 'contentType.recipe',
		urlKey: 'recipes',
	},
	[ContentType.RECIPE_BOOK]: {
		color: '#00BCB8',
		id: ContentType.RECIPE_BOOK,
		pluralTranslationKey: 'common.collection.plural',
		translationKey: 'contentType.recipeBook',
		urlKey: 'collections',
	},
	[ContentType.PACK_HOLDER]: {
		color: '#000000',
		id: ContentType.PACK_HOLDER,
		pluralTranslationKey: 'common.recipeBookCarousel.plural',
		translationKey: 'contentType.recipeBookCarousel',
		urlKey: 'recipe-book-carousels',
	},
	[ContentType.PINNED_HOLDER]: {
		color: '#000000',
		id: ContentType.PINNED_HOLDER,
		pluralTranslationKey: 'common.recipeCarousel.plural',
		translationKey: 'contentType.recipeCarousel',
		urlKey: 'recipe-carousels',
	},
	[ContentType.PRODUCT_RECIPE_BOOK]: {
		color: '#000000',
		id: ContentType.PRODUCT_RECIPE_BOOK,
		pluralTranslationKey: 'common.productRecipeList.plural',
		translationKey: 'contentType.productRecipeList',
		urlKey: 'product-recipe-lists',
	},
	[ContentType.PUSH_MESSAGE]: {
		color: '#E6A219',
		id: ContentType.PUSH_MESSAGE,
		pluralTranslationKey: 'common.pushMessage.plural',
		translationKey: 'contentType.pushMessage',
		urlKey: 'push-messages',
	},
};
