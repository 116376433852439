/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HalModel, ModelConfig } from 'ngx-hal';

@ModelConfig({
	type: 'CountryProfile',
	endpoint: 'Profile',
})
export class CountryProfile extends HalModel {}
