/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum DomainId {
	GLOBAL = 'GLOBAL',
}

export const domainData = {
	[DomainId.GLOBAL]: {
		id: DomainId.GLOBAL,
	},
};
