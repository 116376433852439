/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { Allergen } from '../enums/allergen.enum';
import { IngredientCreator } from '../enums/ingredient-creator.enum';
import { IngredientStatus } from '../enums/ingredient-status.enum';
import { IngredientType } from '../enums/ingredient-type.enum';
import {
	IngredientUnit,
	ingredientUnitConversionDependencies,
} from '../enums/ingredient-unit.enum';
import { Language, languageData } from '../enums/language.enum';
import { contains } from '../utils/helpers/helpers';
import { FoodCompositionForFood } from './food-composition-for-food.model';
import { FoodDatabase } from './food-database.model';
import { FoodUnitConversion } from './food-unit-conversion.model';
import { HalDatastoreModel } from './hal-datastore-model.model';
import { IngredientTranslation } from './ingredient-translation.hal.model';
import { NutritionInfo } from './nutrition-info.model';
import { SpecificConversion } from './specific-conversion.hal.model';

@ModelConfig({
	type: 'Food',
})
export class Food extends HalDatastoreModel {
	public static modelType = 'Food';

	@Attribute()
	@FormAttribute()
	public shortId: string;

	@Attribute({
		excludeFromPayload: true,
	})
	public nameSingular: string;

	@Attribute({
		excludeFromPayload: true,
	})
	public namePlural: string;

	@Attribute()
	@FormAttribute()
	public status: IngredientStatus;

	@Attribute({
		externalName: 'type',
	})
	@FormAttribute()
	public ingredientType: IngredientType;

	@Attribute()
	@FormAttribute()
	public upc: string;

	@Attribute()
	@FormAttribute()
	public referencedRecipesCount: number;

	@Attribute()
	@FormAttribute()
	public allergens: Array<Allergen>;

	@Attribute()
	@FormAttribute()
	public comment: string;

	@Attribute()
	@FormAttribute()
	public createdBy: IngredientCreator;

	@Attribute()
	@FormAttribute()
	public dbMappingAnalysed: boolean;

	@Attribute()
	@FormAttribute()
	public unitConversionsAnalysed: boolean;

	@Attribute()
	@FormAttribute()
	public allergensAnalysed: boolean;

	@Attribute()
	@FormAttribute()
	public notTranslatable: boolean;

	@Attribute()
	@FormAttribute()
	public lockedIngredient: boolean;

	// Not included when fetching without a `country` parameter
	@HasOne({ propertyClass: FoodCompositionForFood })
	public composition: FoodCompositionForFood;

	@HasMany({ itemsType: FoodCompositionForFood })
	public compositions: Array<FoodCompositionForFood>;

	@HasMany({ itemsType: FoodUnitConversion })
	public conversions: Array<FoodUnitConversion>;

	@HasMany({
		itemsType: IngredientTranslation,
	})
	@FormHasMany()
	public translations: Array<IngredientTranslation>;

	public get ingredientTranslations(): Array<IngredientTranslation> {
		return this.translations;
	}

	@HasMany({
		itemsType: History,
	})
	public history: Array<History>;

	@HasMany({
		itemsType: NutritionInfo,
	})
	@FormHasMany()
	public nutritionInfo: Array<NutritionInfo>;

	@HasMany({
		itemsType: SpecificConversion,
	})
	@FormHasMany()
	public specificConversions: Array<SpecificConversion> = [];
	// TODO General recipes remove default value when API is ready

	public getTranslation(translationLanguage?: Language): IngredientTranslation {
		let language = translationLanguage;

		if (!this.ingredientTranslations.length) {
			return {
				nameSingular: '-',
				namePlural: '-',
				language: null,
			} as IngredientTranslation;
		}

		if (!language) {
			language = this.ingredientTranslations[0].language;
		}

		const matchedTranslations: Array<IngredientTranslation> = this.ingredientTranslations.filter(
			(ingredientTranslation) => ingredientTranslation.language === language,
		);

		if (matchedTranslations.length) {
			return matchedTranslations[0];
		}

		return this.ingredientTranslations[0];
	}

	// TODO use languageService for this
	public getLanguage(language: Language = Language.GERMAN): string {
		const translation = this.getTranslation(language);
		const languageDataObject = languageData[translation.language];

		if (languageDataObject) {
			return languageDataObject.label;
		}

		return translation.language;
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleFoodEndpoint,
			collectionEndpoint: this.datastore.rootApi.foodCollectionEndpoint,
		};
	}

	public requiredConversionsForUnit(unit: IngredientUnit): Array<IngredientUnit> {
		const ingredientDependency = ingredientUnitConversionDependencies[unit];

		if (this.id && ingredientDependency) {
			const conversions: Array<FoodUnitConversion> = this.conversions;
			const conversionsSourceUnits = conversions.map((conversion) => conversion.source.unit);

			const hasRequiredConversion: boolean = ingredientDependency.requiresAtLeastOneConversion.some(
				(requiredConversion: IngredientUnit) =>
					contains(conversionsSourceUnits, requiredConversion),
			);

			return hasRequiredConversion ? [] : ingredientDependency.requiresAtLeastOneConversion;
		} else {
			return ingredientDependency ? ingredientDependency.requiresAtLeastOneConversion : [];
		}
	}

	public databasesWithMissingNutritionInfo(
		availableDatabases: Array<FoodDatabase>,
	): Array<FoodDatabase> {
		const missingDatabases: Array<FoodDatabase> = [];

		const mappedDatabases: Array<string> = this.compositions.map(
			({ composition }: FoodCompositionForFood) => `${composition.source}_${composition.provider}`,
		);

		availableDatabases.forEach((availableDatabase: FoodDatabase) => {
			const databaseSource = `${availableDatabase.source}_${availableDatabase.provider}`;
			if (!mappedDatabases.includes(databaseSource)) {
				missingDatabases.push(availableDatabase);
			}
		});

		return missingDatabases;
	}
}
