/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HasMany, Link, ModelConfig } from 'ngx-hal';
import { removeDuplicatesBy } from '../utils/helpers/helpers';
import { Country } from './country.hal.model';
import { HalDatastoreModel } from './hal-datastore-model.model';
import { VariantAvailability } from './variant-availability.model';

@ModelConfig({
	type: 'ProductVariant',
})
export class ProductVariant extends HalDatastoreModel {
	@Attribute()
	@FormAttribute()
	public ctn: string;

	@Attribute()
	@FormAttribute()
	public ctv: string;

	@Link()
	public prxAssets: string;

	@Link()
	public prxSupport: string;

	@HasMany({
		itemsType: VariantAvailability,
	})
	public availabilities: Array<VariantAvailability>;

	public get displayName(): string {
		if (!this.ctv) {
			return this.ctn;
		}

		return `${this.ctn}/${this.ctv}`;
	}

	public get availableInCountries(): Array<Country> {
		return removeDuplicatesBy(
			this.availabilities.map((availability) => availability.country),
			'code',
		);
	}
}
