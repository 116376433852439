/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HalModel, ModelConfig } from 'ngx-hal';

import { IngredientUnit } from '../enums/ingredient-unit.enum';

@ModelConfig({
	type: 'SpecificConversion',
	endpoint: 'SpecificConversion',
})
export class SpecificConversion extends HalModel {
	@Attribute()
	public sourceQuantity: number;

	@Attribute()
	public sourceUnit: IngredientUnit;

	@Attribute()
	public destinationQuantity: number;

	@Attribute()
	public destinationUnit: IngredientUnit;
}
