/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';

import { DomainService } from 'app/services/domain/domain.service';
import { LanguageService } from 'app/services/language/language.service';

@NgModule({
	providers: [LanguageService, DomainService],
})
export class DomainModule {}
