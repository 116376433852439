/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';

import { ConstantService } from 'app/services/constant/constant.service';
import { AuthService } from 'app/services/auth/auth.service';
import { APP_CONFIG, IAppConfig } from 'app/app.config';
import { StorageService } from 'app/services/storage/storage.service';
import { HttpService } from 'app/services/http/http.service';
import { UserService } from 'app/services/user/user.service';
import { Router } from '@angular/router';
import { AdministratorService } from '../administrator/administrator.service';
import { SessionStorageService } from '../storage/session-storage/session-storage.service';

@NgModule({
	providers: [
		{
			provide: AuthService,
			useFactory: (
				config: IAppConfig,
				storageService: StorageService,
				sessionStorageService: SessionStorageService,
				httpService: HttpService,
				userService: UserService,
				administrationService: AdministratorService,
				constantService: ConstantService,
				router: Router,
			) =>
				new AuthService(
					config,
					storageService,
					sessionStorageService,
					httpService,
					userService,
					administrationService,
					constantService,
					router,
				),
			deps: [
				APP_CONFIG,
				StorageService,
				SessionStorageService,
				HttpService,
				UserService,
				AdministratorService,
				ConstantService,
				Router,
			],
		},
	],
})
export class AuthModule {}
