/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, ModelConfig } from 'ngx-hal';
import { UserRole } from '../enums/user-role.enum';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { Country } from './country.hal.model';
import { HalDatastoreModel } from './hal-datastore-model.model';
import { Organization } from './organization.hal.model';

@ModelConfig({
	type: 'Role',
})
export class Role extends HalDatastoreModel {
	@Attribute()
	@FormAttribute()
	public role: UserRole;

	@HasMany({
		itemsType: Organization,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (initial: Array<Organization>, current: Array<Organization>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item) => item.id,
			}),
	})
	public organizations: Array<Organization>;

	public get countries(): Array<Country> {
		return this.organizations.map((organization) => organization.countries).flat();
	}

	public get organizationNames(): string {
		return this.organizations
			.map((organization) => organization.name)
			.sort()
			.join(', ');
	}
}
