/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HttpResponse } from '@angular/common/http';
import { EtagHalStorage, EtagStorageModel, HalDocument, HalModel } from 'ngx-hal';
import { Observable, of } from 'rxjs';

interface ICacheFirstStorageModel<T extends HalModel> extends EtagStorageModel<T> {
	timestamp: number;
}

export class CacheFirstStorage extends EtagHalStorage {
	public makeGetRequestWrapper<T extends HalModel>(
		urls: { originalUrl: string; cleanUrl: string; urlWithParams: string },
		cachedResource: T | HalDocument<T>,
		originalGetRequest$: Observable<T | HalDocument<T>>,
	): Observable<T | HalDocument<T>> {
		if (cachedResource) {
			return of(cachedResource);
		}

		return originalGetRequest$;
	}

	public save<T extends HalModel>(
		model: T | HalDocument<T>,
		response?: HttpResponse<T>,
		alternateUniqueIdentifiers: Array<string> = [],
	): Array<ICacheFirstStorageModel<T>> {
		const storedModels: Array<EtagStorageModel<T>> = super.save(
			model,
			response,
			alternateUniqueIdentifiers,
		);
		const enrichedStoredModels: Array<ICacheFirstStorageModel<T>> = storedModels as Array<
			ICacheFirstStorageModel<T>
		>;

		enrichedStoredModels.forEach((storedModel: ICacheFirstStorageModel<T>) => {
			storedModel.timestamp = Date.now();
		});

		return enrichedStoredModels;
	}

	protected getRawStorageModel<T extends HalModel>(
		uniqueModelIdentifier: string,
	): ICacheFirstStorageModel<T> {
		return super.getRawStorageModel(uniqueModelIdentifier) as ICacheFirstStorageModel<T>;
	}
}
