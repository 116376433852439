/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum DeviceCategoryGroup {
	ALL_APPLIANCES_GROUP = 'ALL_APPLIANCES_GROUP',
	KITCHEN_APPLIANCES_GROUP = 'KITCHEN_APPLIANCES_GROUP',
	COFFEE_APPLIANCES_GROUP = 'COFFEE_APPLIANCES_GROUP',
	GARMENT_CARE_APPLIANCES_GROUP = 'GARMENT_CARE_APPLIANCES_GROUP',
}

export enum DeviceCategoryEnum {
	AICON = 'ALL_IN_ONE_COOKER',
	AIRFRYER = 'AIRFRYER',
	ALL_IN_ONE_IRON = 'ALL_IN_ONE_IRON',
	BLENDER = 'BLENDER',
	ESPRESSO_MACHINE = 'ESPRESSO_MACHINE',
	HANDHELD_STEAMER = 'HANDHELD_STEAMER',
	JUICER = 'JUICER',
	KITCHEN_MACHINE = 'KITCHEN_MACHINE',
	NO_DEVICE = 'NONE',
	NUTRIMAX = 'AIR_COOKER',
	PASTA_MAKER = 'PASTA_MAKER',
	RICE_COOKER = 'RICE_COOKER',
	STEAM_GENERATOR_IRON = 'STEAM_GENERATOR_IRON',
	STEAM_IRON = 'STEAM_IRON',
}

export const deviceCategoryData: Record<DeviceCategoryEnum, { iconPath: string }> = {
	[DeviceCategoryEnum.AICON]: { iconPath: 'ic-aicon' },
	[DeviceCategoryEnum.AIRFRYER]: { iconPath: 'ic-airfryer' },
	[DeviceCategoryEnum.ALL_IN_ONE_IRON]: { iconPath: 'ic-all-in-one-iron' },
	[DeviceCategoryEnum.BLENDER]: { iconPath: 'ic-blender' },
	[DeviceCategoryEnum.ESPRESSO_MACHINE]: { iconPath: 'ic-espresso-machine' },
	[DeviceCategoryEnum.HANDHELD_STEAMER]: { iconPath: 'ic-garment-steamer' },
	[DeviceCategoryEnum.JUICER]: { iconPath: 'ic-blender' },
	[DeviceCategoryEnum.KITCHEN_MACHINE]: { iconPath: 'ic-kitchen-machine' },
	[DeviceCategoryEnum.NO_DEVICE]: { iconPath: 'ic-no-device' },
	[DeviceCategoryEnum.NUTRIMAX]: { iconPath: 'ic-nutrimax' },
	[DeviceCategoryEnum.PASTA_MAKER]: { iconPath: 'ic-pasta-maker' },
	[DeviceCategoryEnum.RICE_COOKER]: { iconPath: 'ic-rice-cooker' },
	[DeviceCategoryEnum.STEAM_GENERATOR_IRON]: { iconPath: 'ic-steam-generator-iron' },
	[DeviceCategoryEnum.STEAM_IRON]: { iconPath: 'ic-steam-iron' },
};
