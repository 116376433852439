/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */
import { Attribute, HasMany, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { ElectricSystem } from '../enums/electric-system.enum';
import { OrganizationName } from '../enums/organization-name.enum';
import { UnitSystemSlug } from '../enums/unit-system.enum';
import { removeDuplicates } from '../utils/helpers/helpers';
import { Language } from './../enums/language.enum';
import { Country } from './country.hal.model';
import { HalDatastoreModel } from './hal-datastore-model.model';

@ModelConfig({
	type: 'Organization',
})
export class Organization extends HalDatastoreModel {
	@Attribute()
	public name: string;

	@HasMany({
		itemsType: Country,
	})
	public countries: Array<Country>;

	public get isGlobal(): boolean {
		return this.name === OrganizationName.GLOBAL;
	}

	public get availableElectricSystems(): Array<ElectricSystem> {
		if (this.isGlobal) {
			return [ElectricSystem['110V'], ElectricSystem['220V']];
		} else {
			return removeDuplicates(this.countries.map((country: Country) => country.electricSystem));
		}
	}

	public get availableLanguages(): Array<Language> {
		return removeDuplicates(this.countries.map((country) => country.supportedLanguages).flat());
	}

	public get unitSystem(): UnitSystemSlug {
		return this.countries[0].unitSystem;
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.organizationCollectionEndpoint,
		};
	}
}
