/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { DatastoreService } from 'ngx-hal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryCode } from '../../enums/country-code.enum';
import { Language } from '../../enums/language.enum';
import { IDictionary } from '../../interfaces/dictionary.interface';
import { ISelectInterface2 } from '../../interfaces/select.interface';
import { Country } from '../../models/country.hal.model';
import { Domain } from '../../models/domain.model';
import { Organization } from '../../models/organization.hal.model';
import { contains, removeDuplicates } from '../../utils/helpers/helpers';
import { transformLanguageForHalApi } from '../../utils/helpers/language-transformer/language-transformer.helper';
import { HalModelService } from '../hal-model/hal-model.service';
import { LanguageService } from '../language/language.service';

@Injectable()
export class OrganizationService extends HalModelService<Organization> {
	private cachedOrganizations: Array<Organization>;

	constructor(
		protected datastore: DatastoreService,
		private readonly languageService: LanguageService,
	) {
		super(datastore, Organization);
	}

	public get organizations(): Array<Organization> {
		return this.cachedOrganizations;
	}

	public get organizationCountries(): Array<Country> {
		return this.cachedOrganizations.map((organization) => organization.countries).flat();
	}

	public initOrganizations(): Observable<Array<Organization>> {
		const requestOptions = {
			headers: { 'Accept-language': transformLanguageForHalApi(Language.ENGLISH) },
		};

		return this.find({ size: 1000 }, false, ['countries'], requestOptions).pipe(
			map((organizations: Array<Organization>) => {
				this.cachedOrganizations = [...organizations].sort((a, b) => a.name.localeCompare(b.name));
				return this.cachedOrganizations;
			}),
		);
	}

	/**
	 * @deprecated TODO NUT-57612: remove this method
	 */
	public getOrganizationsForSelect(): Array<ISelectInterface2<{ id: string }>> {
		return this.organizations.map((organization: Organization) => ({
			value: { id: organization.name },
			label: organization.name,
		}));
	}

	public getOrganizationsForSelectField(): Array<ISelectInterface2<string>> {
		return this.organizations.map((organization: Organization) => ({
			value: organization.name,
			label: organization.name,
		}));
	}

	public getCountrySetName(countrySet: Array<Country>, strictCheck = true): string {
		const organization: Organization = this.getCountrySetOrganization(countrySet, strictCheck);

		if (!organization) {
			console.log(
				'Unknown organization for the country set: ',
				countrySet
					.map((country: Country) => (country ? country.code : 'unknown country code'))
					.join(' ,'),
			);
			return 'Unknown organization';
		}

		return organization.name;
	}

	public getCountrySet(organizationName: string): Array<Country> {
		return this.countrySets[organizationName];
	}

	public getCountryOrganization(country: Country): Organization {
		return this.organizations.find((organization: Organization) => {
			const organizationCountryCodes: Array<string> = organization.countries.map(
				(organizationCountry: Country) => organizationCountry.code,
			);
			return contains(organizationCountryCodes, country.code);
		});
	}

	public getCountrySetSupportedLanguages(countrySet: Array<Country>): Array<Language> {
		if (this.isGlobalCountrySet(countrySet)) {
			const allLanguages: Array<Language> = Object.keys(
				this.languageService.selectableLanguages,
			) as Array<Language>;
			return removeDuplicates(allLanguages).sort((a, b) => a.localeCompare(b));
		}

		const countryLanguages: Array<Language> = countrySet.reduce(
			(languages: Array<Language>, country: Country) => {
				languages.push(...country.supportedLanguages);
				return languages;
			},
			[],
		);

		return removeDuplicates(countryLanguages).sort((a, b) => a.localeCompare(b));
	}

	public isGlobalCountrySet(countrySet: Array<Country>): boolean {
		return countrySet.length && countrySet[0].code === CountryCode.GLOBAL;
	}

	public getCountrySetOrganization(countrySet: Array<Country>, strictCheck = true): Organization {
		return this.organizations.find((organization: Organization) => {
			if (
				strictCheck &&
				organization.countries &&
				organization.countries.length !== countrySet.length
			) {
				return false;
			}

			const checkingMethod: 'every' | 'some' = strictCheck ? 'every' : 'some';

			return countrySet[checkingMethod]((country: Country) => {
				const countryCodes: Array<string> = organization.countries.map(
					(organizationCountry: Country) => organizationCountry.code,
				);
				return contains(countryCodes, country?.code);
			});
		});
	}

	/**
	 * If countries belong to multiple organizations then common organization is global
	 */
	public getCommonCountryOrganization(countrySet: Array<Country>): Organization {
		const organizations: Array<Organization> = this.getAllOrganizationsFromCountrySet(countrySet);
		return organizations.length === 1 ? organizations[0] : this.globalOrganization;
	}

	public getAllOrganizationsFromCountrySet(countrySet: Array<Country>): Array<Organization> {
		const organizations: Set<Organization> = countrySet.reduce(
			(uniqueOrganizations: Set<Organization>, country: Country) =>
				uniqueOrganizations.add(this.getCountryOrganization(country)),
			new Set(),
		);

		return Array.from(organizations);
	}

	public getOrganizationCountryCodes(organizationNames: Array<string>): string {
		const managingCountries: Array<Country> = [].concat.apply(
			[],
			organizationNames.map((organizationName: string) => this.getCountrySet(organizationName)),
		);

		return managingCountries
			.map((country: Country) => country.code)
			.sort()
			.join();
	}

	public getOrganizationsFromDomains(domains: Array<Domain>): Array<Organization> {
		return this.organizations.filter((organization: Organization) =>
			domains.find((domain: Domain) => domain.name === organization.name),
		);
	}

	public areCountriesFromSameOrganization(countries: Array<Country>): boolean {
		if (!countries.length) {
			// Neverland
			return true;
		}

		const firstOrganization = this.getCountryOrganization(countries[0]);

		return countries.every(
			(country) => this.getCountryOrganization(country).id === firstOrganization.id,
		);
	}

	public get globalOrganization(): Organization {
		return this.organizations.find((organization: Organization) => organization.isGlobal);
	}

	private get countrySets(): IDictionary<Array<Country>> {
		return this.cachedOrganizations.reduce(
			(countrySets: IDictionary<Array<Country>>, organization: Organization) => {
				countrySets[organization.name] = organization.countries;
				return countrySets;
			},
			{},
		);
	}
}
