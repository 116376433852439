/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { inject, Injectable, Injector } from '@angular/core';
import { DatastoreService, HalDocument } from 'ngx-hal';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserStatus } from '../../enums/user-status.enum';
import { AdministratorFormStore } from '../../forms/administrator/administrator.form-store';
import { IRequestInfo } from '../../interfaces/request-info.interface';
import { AdministratorRoot } from '../../models/administrator-root.model';
import { createQueryParams } from '../../utils/helpers/create-query-params/create-query-params.helper';
import { HalModelService } from '../hal-model/hal-model.service';
import { RootApiService } from '../root-api/root-api.service';
import { AdministratorFormObject } from './../../forms/administrator/administrator.form-object';
import { Administrator } from './../../models/administrator.model';

@Injectable({
	providedIn: 'root',
})
export class AdministratorService extends HalModelService<Administrator> {
	private readonly injector: Injector = inject(Injector);

	constructor(
		protected readonly datastore: DatastoreService,
		protected readonly rootApiService: RootApiService,
	) {
		super(datastore, Administrator);
	}

	public fetchCurrentAdministrator(): Observable<Administrator> {
		return this.datastore.findOne(
			Administrator,
			null,
			[
				{
					name: 'roles.organizations.countries',
					options: { params: { size: 100 } },
				},
			],
			{},
			this.rootApiService.rootApi.administratorSelfEndpoint,
		);
	}

	/**
	 * Use only for initial tenant selection
	 */
	public fetchCurrentAdministratorConfig(): Observable<AdministratorRoot> {
		return this.datastore
			.request('GET', `AdministratorRoot/self`, {}, AdministratorRoot, true)
			.pipe(
				switchMap((administratorSelfRoot) =>
					administratorSelfRoot.fetchRelationships('tenants.icon'),
				),
			);
	}

	public changeStatus(administrator: Administrator): Observable<Administrator> {
		const oldStatus = administrator.status;
		const newStatus = administrator.isActive ? UserStatus.INACTIVE : UserStatus.ACTIVE;
		administrator.status = newStatus;

		return administrator.update({}, { specificFields: ['status'] }).pipe(
			catchError((error) => {
				administrator.status = oldStatus;
				return throwError(error);
			}),
		);
	}

	public createAdministratorForm(administrator?: Administrator): AdministratorFormStore {
		const newAdministrator: Administrator = administrator || this.createNewModel();

		const administratorFormObject = new AdministratorFormObject(
			newAdministrator,
			null,
			this.injector,
		);
		return administratorFormObject.form as AdministratorFormStore;
	}

	public search({
		filter,
		page,
		includes = [],
		headers = {},
		sort,
	}: IRequestInfo): Observable<HalDocument<Administrator>> {
		const requestOptions = { headers };
		const params = createQueryParams(page, filter, sort);
		return this.find(params, true, includes, requestOptions, requestOptions);
	}
}
