/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as AttributeNgxHal, HalModel, HasOne, ModelConfig } from 'ngx-hal';

@ModelConfig({
	type: 'AttributeOption',
	// endpoint is empty, because we'll fetch this resource only as a relationship on the Attribute resource
	endpoint: '',
})
export class AttributeOption extends HalModel {
	public static modelType = 'AttributeOption';

	@AttributeNgxHal()
	public isPreselected: boolean;

	@AttributeNgxHal()
	public label: string;

	@AttributeNgxHal()
	public identifier: string;

	@HasOne({
		propertyClass: null,
	})
	public value: unknown;

	public get valueLink(): string {
		return this.links.value?.href;
	}
}
